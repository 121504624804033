import {Suspense, lazy} from 'react';
import type {PartialRouteObject} from 'react-router';
import AuthGuard from './material/AuthGuard';
import DashboardLayout from './material/dashboard/DashboardLayout';
import GuestGuard from './material/GuestGuard';
import LoadingScreen from './material/LoadingScreen';
import PageUsers from "./pages/users/page.user";
import NewUser from "./pages/users/new.user";
import UpdateUser from "./pages/users/update.user";
import PageRole from "./pages/roles/page.role";
import NewRole from "./pages/roles/new.role";
import NewPolicy from "./pages/roles/new.policy";
import PermissionGuard from "./components/PermissionGuard/PermissionGuard";
import PasswordRecovery from "./pages/authentication/password-recovery/PasswordRecovery";
import PasswordReset from "./pages/authentication/password-reset/PasswordReset";
import NewGroup from "./pages/roles/new.group";
import PaginaRichieste from './pages/richieste/pagina.richieste';
import Dettaglio from './pages/richieste/dettaglio';
import PaginaDashboardCommissionale from './pages/dashboardCommissionale/pagina.dashboardCommissionale';
import PaginaNote from './pages/note/pagina.note';
import DettaglioNota from './pages/note/dettaglioNote';
import NuovaRichiesta from './pages/richieste/nuovaRichiesta';
import NuovaNota from './pages/note/nuovaNota';
// YEOMAN IMPORT ANCHOR */

import UpdateReport from "src/pages/report/update.report"
import NewReport from "src/pages/report/new.report"
import PageReport from "src/pages/report/page.report"
import AccettaPreventivo from './pages/richieste/accettaPreventivo';
import ApprovaPreventivo from './pages/richieste/accettaPreventivo';
const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen/>}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/account/pagina.account')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/pagina.dashboard')));


const routes: PartialRouteObject[] = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login/>
                    </GuestGuard>
                )
            },

            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register/>
                    </GuestGuard>
                )
            },
            {
                path: 'register-unguarded',
                element: <Register/>
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery/>
            },
            {
                path: 'password-reset',
                element: <PasswordReset/>
            },
        ]
    },
    {
        path: '*',
        element: (
            <AuthGuard>
                <DashboardLayout/>
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <Finance/>
            },
            {
                path: 'account',
                element: <Account/>
            },
            {
                path: 'richieste',
                children: [
                    {
                        path: '/',
                        element: <AuthGuard>
                            <PaginaRichieste/>
                        </AuthGuard>
                    },
                    {
                        path: '/dettagli/:id/:statoRichiesta/:statoPratica',
                        element: <Dettaglio/>
                    },
                    {
                        path: '/nuovaRichiesta',
                        element: <NuovaRichiesta/>
                    },
                    {
                        path: '/accettaPreventivo/:richiestaId/:preventivoId/:stato/',
                        element: <ApprovaPreventivo/>
                    }
                ]
            },
            {
                path: 'commissionale',
                element: <PaginaDashboardCommissionale/>
            },
            {
                path: 'note',
                children: [
                    {
                        path: '/',
                        element: <PaginaNote/>
                    },
                    {
                        path: '/dettaglioNote/:id',
                        element: <DettaglioNota/>
                    },
                    {
                        path: '/nuovaNota',
                        element: <NuovaNota/>
                    }
                ]

            },
            {
                path: 'users',
                children: [
                    {
                        path: '/',
                        element:
                            <PermissionGuard action={"allow"} resource={"users"}>
                                <PageUsers/>
                            </PermissionGuard>
                    },
                    {
                        path: 'new',
                        element: <NewUser/>
                    },
                    {
                        path: 'modifica/:id',
                        element: <UpdateUser/>
                    }
                ]
            },
            {
                path: 'roles',
                children: [
                    {
                        path: '/',
                        element: <PageRole/>
                    },
                    {
                        path: 'new/ruolo',
                        element: <NewRole/>
                    },
                    {
                        path: 'new/gruppo',
                        element: <NewGroup/>
                    },
                    {
                        path: 'nuova/policy',
                        element: <NewPolicy/>
                    },
                ]
            },
            // YEOMAN ANCHOR */
{
                path: 'reports',
                children: [
                    {
                        path: '/',
                        element:
                                <PageReport/>
                    },
                    {
                        path: 'new',
                        element:
                                <NewReport/>
                    },
                    {
                        path: 'update/:id',
                        element:
                                <UpdateReport/>
                    },
                ]
            },

        ]
    },
];

export default routes;
