import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  Time: any;
  UnixDate: any;
  Upload: any;
};





export type AccettaPreventivo = {
  richiestaId?: Maybe<Scalars['String']>;
  preventivoId?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
  allegati?: Maybe<Allegati>;
};

export type Allegati = {
  base64zip?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<FileConIntero>>>;
};

export type AllegatiConIntero = {
  base64zip?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<FileConIntero>>>;
};

export type AllegatoSingolo = {
  pdfBase64?: Maybe<Scalars['String']>;
  inizioDocumentiReddituali: Scalars['Int'];
  fineDocumentiReddituali: Scalars['Int'];
  inizioPrivacySMC: Scalars['Int'];
  finePrivacySMC: Scalars['Int'];
  inizioDocumentiAnagrafici: Scalars['Int'];
  fineDocumentiAnagrafici: Scalars['Int'];
  inizioDocumentiIntegrativi: Scalars['Int'];
  fineDocumentiIntegrativi: Scalars['Int'];
};

export type Cliente = {
  __typename?: 'Cliente';
  Id?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  DataNascita?: Maybe<Scalars['String']>;
  cittaNascita?: Maybe<Scalars['String']>;
  provinciaNascita?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
};

export type Configurazioni = {
  __typename?: 'Configurazioni';
  durata?: Maybe<Array<Maybe<Scalars['Int']>>>;
  prodotti?: Maybe<Array<Maybe<Prodotti>>>;
  items?: Maybe<Array<Maybe<Items>>>;
};

export type Credentials = {
  UserId?: Maybe<Scalars['String']>;
  SessionID?: Maybe<Scalars['String']>;
};

export type DashboardCommissionale = {
  __typename?: 'DashboardCommissionale';
  listPraticheMaturateFiliali?: Maybe<Array<Maybe<ListStruct>>>;
  listMontanteLordoMaturatoFiliali?: Maybe<Array<Maybe<ListStruct>>>;
  praticheMaturateSocieta?: Maybe<StructQuantitaValore>;
  montanteLordoMaturatoSocieta?: Maybe<StructQuantitaValore>;
  listPraticheTransitorieFiliali?: Maybe<Array<Maybe<ListStruct>>>;
  listMontanteLordoTransitorioFiliali?: Maybe<Array<Maybe<ListStruct>>>;
  praticheTransitorioSocieta?: Maybe<StructQuantitaValore>;
  montanteLordoTransitorioSocieta?: Maybe<StructQuantitaValore>;
  listPratichePotenzialiFiliali?: Maybe<Array<Maybe<ListStruct>>>;
  listMontanteLordoPotenzialeFiliali?: Maybe<Array<Maybe<ListStruct>>>;
  pratichePotenzialeSocieta?: Maybe<StructQuantitaValore>;
  montanteLordoPotenzialeSocieta?: Maybe<StructQuantitaValore>;
  listPraticheMaturateMeseFiliali?: Maybe<Array<Maybe<ListPraticheMaturateMeseFiliali>>>;
  listPraticheMaturateMeseSocietaMediazione?: Maybe<Array<Maybe<MeseValore>>>;
  listPraticheMaturateClusterFiliali?: Maybe<Array<Maybe<ListPraticheMaturateClusterFiliale>>>;
  listPraticheMaturateClusterSocieta?: Maybe<Array<Maybe<StructClusterQuantitaValore>>>;
  status?: Maybe<Status>;
  listPraticheMaturateClusterUtente?: Maybe<Array<Maybe<StructClusterQuantitaValore>>>;
  listPraticheMaturateMeseUtente?: Maybe<Array<Maybe<MeseValore>>>;
  montanteLordoPotenzialeUtente?: Maybe<StructQuantitaValore>;
  pratichePotenzialiUtente?: Maybe<StructQuantitaValore>;
  montanteLordoTransitorieUtente?: Maybe<StructQuantitaValore>;
  praticheTransitorieUtente?: Maybe<StructQuantitaValore>;
  montanteLordoMaturatoUtente?: Maybe<StructQuantitaValore>;
  praticheMaturateUtente?: Maybe<StructQuantitaValore>;
};

export type DashboardGenerale = {
  __typename?: 'DashboardGenerale';
  dataUltimaElaborazione?: Maybe<Scalars['String']>;
  elencoUltimeNote?: Maybe<Array<Maybe<ElencoUltimeNote>>>;
  elencoUltimePratiche?: Maybe<Array<Maybe<ElencoUltimePratiche>>>;
  sintesiMontanti?: Maybe<Array<Maybe<SintesiMontanti>>>;
  sintesiRichiesteVsPratiche?: Maybe<Array<Maybe<SintesiRichiesteVsPratiche>>>;
  sintesiPratiche?: Maybe<Array<Maybe<SintesiPratiche>>>;
  sintesiRichieste?: Maybe<Array<Maybe<SintesiRichieste>>>;
  status?: Maybe<Status>;
};

export type DatoreLavoro = {
  __typename?: 'DatoreLavoro';
  Id?: Maybe<Scalars['String']>;
  ragioneSociale?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
  partitaIva?: Maybe<Scalars['String']>;
  codiceAtc?: Maybe<Scalars['String']>;
  via?: Maybe<Scalars['String']>;
  civico?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  telefono1?: Maybe<Scalars['String']>;
  telefono2?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  personaRiferimento?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  dataInattivazione?: Maybe<Scalars['String']>;
};

export type Dettagli = {
  __typename?: 'Dettagli';
  codice?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
};

export type DocumentiList = {
  __typename?: 'DocumentiList';
  status?: Maybe<Status>;
  documentiList?: Maybe<Array<Maybe<Documento>>>;
};

export type DocumentiListIntero = {
  __typename?: 'DocumentiListIntero';
  status?: Maybe<Status>;
  documentiList?: Maybe<Array<Maybe<DocumentoConIntero>>>;
};

export type Documento = {
  __typename?: 'Documento';
  documentoId?: Maybe<Scalars['String']>;
  tipoDocumento?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
};

export type DocumentoConIntero = {
  __typename?: 'DocumentoConIntero';
  documentoId?: Maybe<Scalars['String']>;
  tipoDocumento?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  base64?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
};

export type DocumentoRichiesta = {
  __typename?: 'DocumentoRichiesta';
  documentoId?: Maybe<Scalars['String']>;
  tipoDocumento?: Maybe<Scalars['Int']>;
  descrizione?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DownloadDoc = {
  __typename?: 'DownloadDoc';
  documentoConIntero?: Maybe<DocumentoConIntero>;
  status?: Maybe<Status>;
};

export type ElencoUltimeNote = {
  __typename?: 'ElencoUltimeNote';
  notaId?: Maybe<Scalars['String']>;
  dataInserimento?: Maybe<Scalars['String']>;
  codice?: Maybe<Scalars['String']>;
  tipoNota?: Maybe<Scalars['Int']>;
  mittenteId?: Maybe<Scalars['String']>;
  fullnameMittente?: Maybe<Scalars['String']>;
  testo?: Maybe<Scalars['String']>;
};

export type ElencoUltimePratiche = {
  __typename?: 'ElencoUltimePratiche';
  praticaId?: Maybe<Scalars['String']>;
  dataUltimaModifica?: Maybe<Scalars['String']>;
  cognomeCliente?: Maybe<Scalars['String']>;
  nomeCliente?: Maybe<Scalars['String']>;
  montanteLordo?: Maybe<Scalars['Float']>;
};

export type Entity = {
  Tipo?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['String']>;
};

export type Erogazione = {
  __typename?: 'Erogazione';
  Id?: Maybe<Scalars['String']>;
  praticaId?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bancaId?: Maybe<Scalars['String']>;
  desrizioneBanca?: Maybe<Scalars['String']>;
  assegno?: Maybe<Scalars['Boolean']>;
};

export type File = {
  tipoDocumento?: Maybe<Scalars['String']>;
  nomeFile?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
};

export type FileConIntero = {
  tipoDocumento?: Maybe<Scalars['Int']>;
  nomeFile?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
};

export type Filters = {
  DataDa?: Maybe<Scalars['String']>;
  DataA?: Maybe<Scalars['String']>;
  CodicePratica?: Maybe<Scalars['String']>;
  Nome?: Maybe<Scalars['String']>;
  Cognome?: Maybe<Scalars['String']>;
  MacroStato?: Maybe<Scalars['String']>;
  StatoPratica?: Maybe<Scalars['String']>;
  BancaErogante?: Maybe<Array<Maybe<Scalars['String']>>>;
  CanaleAcquisizione?: Maybe<Scalars['String']>;
};

export type FiltersRic = {
  DataDa?: Maybe<Scalars['String']>;
  DataA?: Maybe<Scalars['String']>;
  CodicePratica?: Maybe<Scalars['String']>;
  Nome?: Maybe<Scalars['String']>;
  Cognome?: Maybe<Scalars['String']>;
  TipoImpiego?: Maybe<Scalars['String']>;
  StatoRichiesta?: Maybe<Scalars['String']>;
  StatoPratica?: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  subject: Scalars['String'];
  role: Scalars['String'];
  domain: Scalars['String'];
};

export type Identificazione = {
  __typename?: 'Identificazione';
  Id?: Maybe<Scalars['String']>;
  tipoDocumento?: Maybe<Scalars['Int']>;
  numeroDocumento?: Maybe<Scalars['String']>;
  dataRilascio?: Maybe<Scalars['String']>;
  dataScadenza?: Maybe<Scalars['String']>;
  enteRilascio?: Maybe<Scalars['String']>;
  luogoRilascio?: Maybe<Scalars['String']>;
};

export type InputRichiestaPreventivi = {
  credentials?: Maybe<Credentials>;
  cognome?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  cittaNascita?: Maybe<Scalars['String']>;
  provinciaNascita?: Maybe<Scalars['String']>;
  tipoImpiego?: Maybe<Scalars['Int']>;
  dataAssunzione?: Maybe<Scalars['String']>;
  importoRichiesto?: Maybe<Scalars['Int']>;
  telefono?: Maybe<Scalars['String']>;
  cellulare?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
  allegati?: Maybe<AllegatiConIntero>;
};

export type InputRichiestaPreventiviConDocumentoSingolo = {
  credentials?: Maybe<Credentials>;
  cognome?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  cittaNascita?: Maybe<Scalars['String']>;
  provinciaNascita?: Maybe<Scalars['String']>;
  tipoImpiego?: Maybe<Scalars['Int']>;
  dataAssunzione?: Maybe<Scalars['String']>;
  importoRichiesto?: Maybe<Scalars['Int']>;
  telefono?: Maybe<Scalars['String']>;
  cellulare?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
  allegato?: Maybe<AllegatoSingolo>;
};

export type Items = {
  __typename?: 'Items';
  codice?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
};


export type ListDocumenti = {
  __typename?: 'ListDocumenti';
  documentoId?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  tipoDocumento?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ListImpegni = {
  __typename?: 'ListImpegni';
  Id?: Maybe<Scalars['String']>;
  bancaId?: Maybe<Scalars['String']>;
  descrizioneBanca?: Maybe<Scalars['String']>;
  rata?: Maybe<Scalars['Float']>;
  durata?: Maybe<Scalars['Int']>;
  dataDecorrenza?: Maybe<Scalars['String']>;
  rateResidue?: Maybe<Scalars['Int']>;
  estinzione?: Maybe<Scalars['Boolean']>;
  tipoImpegno?: Maybe<Scalars['Int']>;
  importoEstinzione?: Maybe<Scalars['Float']>;
  documentoId?: Maybe<Scalars['String']>;
  statoEstinzione?: Maybe<Scalars['Int']>;
};

export type ListPraticheMaturateClusterFiliale = {
  __typename?: 'ListPraticheMaturateClusterFiliale';
  filialeId?: Maybe<Scalars['String']>;
  listValoreCluster?: Maybe<Array<Maybe<StructClusterQuantitaValore>>>;
};

export type ListPraticheMaturateMeseFiliali = {
  __typename?: 'ListPraticheMaturateMeseFiliali';
  filialeId?: Maybe<Scalars['String']>;
  listValoreMese?: Maybe<Array<Maybe<MeseValore>>>;
};

export type ListStruct = {
  __typename?: 'ListStruct';
  filialeId?: Maybe<Scalars['String']>;
  quantita?: Maybe<Scalars['Int']>;
  valore?: Maybe<Scalars['Float']>;
};

export type MedicoCurante = {
  __typename?: 'MedicoCurante';
  praticaId?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
};

export type MeseValore = {
  __typename?: 'MeseValore';
  mese?: Maybe<Scalars['String']>;
  valore?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  createDocumento: DocumentiListIntero;
  accettaPreventivo: RispostaPreventivoAccettato;
  AddPolicy?: Maybe<Policy>;
  AddGroup?: Maybe<Group>;
  createNota: NotaRestituitaDaAddNota;
  logout: Scalars['Boolean'];
  createReport: Report;
  updateReport: Report;
  deleteReport: Report;
  downloadPrecontrattuale: Scalars['String'];
  downloadReportTemplate: ReportTemplate;
  createUser: TokenDetails;
  updateUser: User;
  updateAvatar: User;
  updateSelfPassword: User;
  updateUserPassword: User;
  recoverPassword: Scalars['Boolean'];
  resetPassword: TokenDetails;
  impersonates: TokenDetails;
  createRole?: Maybe<Role>;
};


export type MutationCreateDocumentoArgs = {
  entity?: Maybe<Entity>;
  allegati?: Maybe<Allegati>;
};


export type MutationAccettaPreventivoArgs = {
  input?: Maybe<AccettaPreventivo>;
};


export type MutationAddPolicyArgs = {
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};


export type MutationAddGroupArgs = {
  subject: Scalars['String'];
  role: Scalars['String'];
};


export type MutationCreateNotaArgs = {
  entity?: Maybe<Entity>;
  notes?: Maybe<Array<Maybe<Note>>>;
};


export type MutationCreateReportArgs = {
  form?: Maybe<ReportInputForm>;
};


export type MutationUpdateReportArgs = {
  id: Scalars['String'];
  form?: Maybe<ReportUpdateForm>;
};


export type MutationDeleteReportArgs = {
  id: Scalars['String'];
};


export type MutationDownloadPrecontrattualeArgs = {
  dati: Scalars['String'];
};


export type MutationDownloadReportTemplateArgs = {
  id: Scalars['String'];
};


export type MutationCreateUserArgs = {
  user?: Maybe<UserInputWithPassword>;
};


export type MutationUpdateUserArgs = {
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
};


export type MutationUpdateAvatarArgs = {
  email: Scalars['String'];
  avatar: Scalars['String'];
};


export type MutationUpdateSelfPasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdateUserPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRecoverPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationImpersonatesArgs = {
  email: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type Nota = {
  __typename?: 'Nota';
  Id?: Maybe<Scalars['String']>;
  TipoNota?: Maybe<Scalars['Int']>;
  Nota?: Maybe<Scalars['String']>;
  DataInserimento?: Maybe<Scalars['String']>;
  UtenteId?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  UtenteNome?: Maybe<Scalars['String']>;
  UtenteCognome?: Maybe<Scalars['String']>;
};

export type NotaRestituitaDaAddNota = {
  __typename?: 'NotaRestituitaDaAddNota';
  Id?: Maybe<Scalars['String']>;
  TipoNota?: Maybe<Scalars['String']>;
  Nota?: Maybe<Scalars['String']>;
  DataInserimento?: Maybe<Scalars['String']>;
  UtenteId?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  UtenteNome?: Maybe<Scalars['String']>;
  UtenteCognome?: Maybe<Scalars['String']>;
};

export type Note = {
  Nota?: Maybe<Scalars['String']>;
  TipoNota?: Maybe<Scalars['Int']>;
};

export type NoteList = {
  __typename?: 'NoteList';
  Id?: Maybe<Scalars['String']>;
  TipoNota?: Maybe<Scalars['String']>;
  Nota?: Maybe<Scalars['String']>;
  DataInserimento?: Maybe<Scalars['String']>;
  UtenteId?: Maybe<Scalars['String']>;
};

export type Policy = {
  __typename?: 'Policy';
  subject: Scalars['String'];
  domain: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
};

export type Pratica = {
  __typename?: 'Pratica';
  id_pratica?: Maybe<Scalars['String']>;
  data_salvataggio?: Maybe<Scalars['String']>;
  codice_pratica?: Maybe<Scalars['String']>;
  stato?: Maybe<Scalars['String']>;
  progressivo?: Maybe<Scalars['String']>;
  segnalazione?: Maybe<Scalars['String']>;
  id_ute_backoffice?: Maybe<Scalars['String']>;
  id_cliente?: Maybe<Scalars['String']>;
  id_preventivo?: Maybe<Scalars['String']>;
  id_utente?: Maybe<Scalars['String']>;
  id_collocatore?: Maybe<Scalars['String']>;
  data_notifica?: Maybe<Scalars['String']>;
  tipo_notifica?: Maybe<Scalars['String']>;
  numero_raccomandata?: Maybe<Scalars['String']>;
  conteggio_estintivo?: Maybe<Scalars['String']>;
  data_richiesta_polizza?: Maybe<Scalars['String']>;
  data_emissione_polizza?: Maybe<Scalars['String']>;
  compagnia_assicurativa_polizza?: Maybe<Scalars['String']>;
  decorrenza?: Maybe<Scalars['String']>;
  spesevive?: Maybe<Scalars['Float']>;
  ImportoAcconto?: Maybe<Scalars['Float']>;
  StatoAcconto?: Maybe<Scalars['String']>;
  SospesaPreAccettazione?: Maybe<Scalars['String']>;
  documenti_firmati?: Maybe<Scalars['String']>;
  data_firma?: Maybe<Scalars['String']>;
  statoPraticaINPS?: Maybe<Scalars['String']>;
  DataUltModAcc?: Maybe<Scalars['String']>;
  ImportoAcconto1?: Maybe<Scalars['Float']>;
  StatoAcconto1?: Maybe<Scalars['String']>;
  DataUltModAcc1?: Maybe<Scalars['String']>;
  idVenditore?: Maybe<Scalars['String']>;
  notificaScandenzaSended?: Maybe<Scalars['String']>;
  dtInvioNotificaScandeza?: Maybe<Scalars['String']>;
  IdCanaleVendita?: Maybe<Scalars['String']>;
  statoAssicurazione?: Maybe<Scalars['String']>;
  dtRichPolizzaBanca?: Maybe<Scalars['String']>;
  dtRicDocs?: Maybe<Scalars['String']>;
  DataApprovazioneBancaErogante?: Maybe<Scalars['String']>;
  documentiList?: Maybe<Array<Maybe<Documento>>>;
  storicoStatiList?: Maybe<Array<Maybe<StoricoStatiList>>>;
  noteList?: Maybe<Array<Maybe<NoteList>>>;
  preventivo?: Maybe<Preventivo>;
};

export type PraticaCqs = {
  __typename?: 'PraticaCqs';
  id?: Maybe<Scalars['String']>;
  codice?: Maybe<Scalars['String']>;
  stato?: Maybe<Scalars['Int']>;
  segnalazione?: Maybe<Scalars['String']>;
  clienteId?: Maybe<Scalars['String']>;
  preventivoId?: Maybe<Scalars['String']>;
  titolareId?: Maybe<Scalars['String']>;
  venditoreId?: Maybe<Scalars['String']>;
  collocatoreId?: Maybe<Scalars['String']>;
  canaleVenditaId?: Maybe<Scalars['String']>;
  dataSalvataggio?: Maybe<Scalars['String']>;
  dataDecorrenza?: Maybe<Scalars['String']>;
  dataApprovazioneBancaErogante?: Maybe<Scalars['String']>;
  dataNotifica?: Maybe<Scalars['String']>;
  notificaScandenzaSended?: Maybe<Scalars['String']>;
  tipoNotifica?: Maybe<Scalars['Int']>;
  numeroRaccomandataNotifica?: Maybe<Scalars['String']>;
  conteggioEstintivo?: Maybe<Scalars['Boolean']>;
  dataRichiestaPolizza?: Maybe<Scalars['String']>;
  dataEmissionePolizza?: Maybe<Scalars['String']>;
  compagniaAssicurativaPolizza?: Maybe<Scalars['String']>;
  statoAssicurazione?: Maybe<Scalars['Int']>;
  dataRichiestaInBancaPolizza?: Maybe<Scalars['String']>;
  speseVive?: Maybe<Scalars['Float']>;
  speseViveConRitenuta?: Maybe<Scalars['Float']>;
  importoAcconto?: Maybe<Scalars['Float']>;
  statoAcconto?: Maybe<Scalars['Int']>;
  dataUltimaModificaAcconto?: Maybe<Scalars['String']>;
  importoSecondoAcconto?: Maybe<Scalars['Float']>;
  statoSecondoAcconto?: Maybe<Scalars['Int']>;
  dataUltimaModificaSecondoAcconto?: Maybe<Scalars['String']>;
  documentiFirmati?: Maybe<Scalars['Boolean']>;
  dataFirmaDocumenti?: Maybe<Scalars['String']>;
  dataRicezioneDocumentiFirmati?: Maybe<Scalars['String']>;
  statoPraticaINPS?: Maybe<Scalars['Int']>;
  dtInvioNotificaScandeza?: Maybe<Scalars['String']>;
};

export type PraticheList = {
  __typename?: 'PraticheList';
  status?: Maybe<Status>;
  praticheList?: Maybe<Array<Maybe<Pratica>>>;
};

export type Preventivi = {
  __typename?: 'Preventivi';
  id?: Maybe<Scalars['String']>;
  codice?: Maybe<Scalars['String']>;
  collaboratoreId?: Maybe<Scalars['String']>;
  dataUltimoSalvataggio?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['String']>;
  tipoProdotto?: Maybe<Scalars['Int']>;
  tipoImpiego?: Maybe<Scalars['Int']>;
  dataAssunzione?: Maybe<Scalars['String']>;
  dataDecorrenza?: Maybe<Scalars['String']>;
  rata?: Maybe<Scalars['Float']>;
  durata?: Maybe<Scalars['Int']>;
  rinnovo?: Maybe<Scalars['Boolean']>;
  deltaMontante?: Maybe<Scalars['Float']>;
  percentualeMargineCollaboratore?: Maybe<Scalars['Float']>;
  importoMargineCollaboratore?: Maybe<Scalars['Float']>;
  bancaEroganteId?: Maybe<Scalars['String']>;
  montanteLordo?: Maybe<Scalars['Float']>;
  costiBancari?: Maybe<Scalars['Float']>;
  assicurazioni?: Maybe<Scalars['Float']>;
  speseRegistro?: Maybe<Scalars['Float']>;
  altreSpese?: Maybe<Scalars['Float']>;
  taeg?: Maybe<Scalars['Float']>;
  teg?: Maybe<Scalars['Float']>;
  tan?: Maybe<Scalars['Float']>;
  nettoErogato?: Maybe<Scalars['Float']>;
  assicurazioneId?: Maybe<Scalars['String']>;
  descrizioneAssicurazione?: Maybe<Scalars['String']>;
  descrizioneBancaErogante?: Maybe<Scalars['String']>;
  descrizioneProdotto?: Maybe<Scalars['String']>;
  importoOverPrestitoFelice?: Maybe<Scalars['Float']>;
  percentualeOverPrestitoFelice?: Maybe<Scalars['Float']>;
  bossId?: Maybe<Scalars['String']>;
  importoOverBoss?: Maybe<Scalars['Float']>;
  percentualeOverBoss?: Maybe<Scalars['Float']>;
};

export type Preventivo = {
  __typename?: 'Preventivo';
  TipoImpiego?: Maybe<Scalars['Int']>;
  TipoProdotto?: Maybe<Scalars['Int']>;
  DataUltimaRata?: Maybe<Scalars['String']>;
  IsRinnovoIbl?: Maybe<Scalars['Boolean']>;
  FullName?: Maybe<Scalars['String']>;
  id_preventivo?: Maybe<Scalars['String']>;
  id_cliente?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  data_nascita?: Maybe<Scalars['String']>;
  data_assunzione?: Maybe<Scalars['String']>;
  tipo_impiego_bis?: Maybe<Scalars['Int']>;
  tipo_qualifica?: Maybe<Scalars['Int']>;
  data_decorrenza?: Maybe<Scalars['String']>;
  montante_lordo?: Maybe<Scalars['Float']>;
  costi_bancari?: Maybe<Scalars['Float']>;
  assicurazioni?: Maybe<Scalars['Float']>;
  spese_registro?: Maybe<Scalars['Float']>;
  altre_spese?: Maybe<Scalars['Float']>;
  margine_percentuale?: Maybe<Scalars['Float']>;
  importo_margine?: Maybe<Scalars['Float']>;
  importo_rata?: Maybe<Scalars['Float']>;
  durata_impegno?: Maybe<Scalars['Int']>;
  tipo_prodotto_bis?: Maybe<Scalars['String']>;
  rinnovo?: Maybe<Scalars['Boolean']>;
  taeg?: Maybe<Scalars['Float']>;
  teg?: Maybe<Scalars['Float']>;
  tan?: Maybe<Scalars['Float']>;
  netto_erogato?: Maybe<Scalars['Float']>;
  id_assicurazione?: Maybe<Scalars['String']>;
  data_salvataggio?: Maybe<Scalars['String']>;
  id_utentesessione?: Maybe<Scalars['String']>;
  nome_prodotto?: Maybe<Scalars['String']>;
  descrizione_assicurazione?: Maybe<Scalars['String']>;
  descrizione_banca?: Maybe<Scalars['String']>;
  descrizione_prodotto?: Maybe<Scalars['String']>;
  importo_over?: Maybe<Scalars['Float']>;
  over_ute?: Maybe<Scalars['Float']>;
  idbanca?: Maybe<Scalars['String']>;
  decorrenza?: Maybe<Scalars['String']>;
  netto_erogato_orig?: Maybe<Scalars['String']>;
  delta_provvigioni?: Maybe<Scalars['String']>;
  progressivo?: Maybe<Scalars['String']>;
  pdfFile?: Maybe<Scalars['String']>;
  pspdfFile?: Maybe<Scalars['String']>;
  deltaMontante_lordo?: Maybe<Scalars['String']>;
  BancaEroganteItem?: Maybe<Scalars['String']>;
  ClienteItem?: Maybe<Scalars['String']>;
  UtenteItem?: Maybe<Scalars['String']>;
  PreventivoOverItem?: Maybe<Scalars['String']>;
  DocumentoLinkItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  TrattativaItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  PraticaItems?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Prodotti = {
  __typename?: 'Prodotti';
  codiceBancaErogante?: Maybe<Scalars['String']>;
  codiceProdotto?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  dettagli?: Maybe<Array<Maybe<Dettagli>>>;
};

export type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  downloadDocumento: DownloadDoc;
  richediPreventivi: RichiestaPreventivo;
  richiediPreventiviConDocumentoSingolo: RichiestaPreventivo;
  chiediUlteriorePreventivo: RispostaUteriorePreventivo;
  datiSingolaRichiesta: RispostaSingolaRichiesta;
  getPolicies?: Maybe<Array<Maybe<Policy>>>;
  getGroups?: Maybe<Array<Maybe<Group>>>;
  ottieniConfigurazioni?: Maybe<Configurazioni>;
  ottieniDashboardCommissionale?: Maybe<DashboardCommissionale>;
  ottieniDashboardGenerale?: Maybe<DashboardGenerale>;
  ottieniPratiche?: Maybe<PraticheList>;
  datiSingolaPratica: SingolaPratica;
  reports?: Maybe<Array<Maybe<Report>>>;
  report: Report;
  ottieniRichieste?: Maybe<RichiestaList>;
  rinunciaRichiesta: RispostaRinunciaRichiesta;
  users?: Maybe<Array<Maybe<User>>>;
  user: User;
  loggedUser: User;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
  roles?: Maybe<Array<Maybe<Role>>>;
};


export type QueryDownloadDocumentoArgs = {
  documentoId?: Maybe<Scalars['String']>;
};


export type QueryRichediPreventiviArgs = {
  input?: Maybe<InputRichiestaPreventivi>;
};


export type QueryRichiediPreventiviConDocumentoSingoloArgs = {
  input?: Maybe<InputRichiestaPreventiviConDocumentoSingolo>;
};


export type QueryChiediUlteriorePreventivoArgs = {
  richiestaId?: Maybe<Scalars['Int']>;
  nota?: Maybe<Scalars['String']>;
};


export type QueryDatiSingolaRichiestaArgs = {
  richiestaId?: Maybe<Scalars['Int']>;
};


export type QueryOttieniPraticheArgs = {
  filters?: Maybe<Filters>;
};


export type QueryDatiSingolaPraticaArgs = {
  id?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
};


export type QueryReportArgs = {
  id: Scalars['String'];
};


export type QueryOttieniRichiesteArgs = {
  filters?: Maybe<FiltersRic>;
};


export type QueryRinunciaRichiestaArgs = {
  richiestaId?: Maybe<Scalars['Int']>;
  nota?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  email: Scalars['String'];
};


export type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export type RapportoLavoro = {
  __typename?: 'RapportoLavoro';
  Id?: Maybe<Scalars['String']>;
  praticaId?: Maybe<Scalars['String']>;
  datoreLavoroId?: Maybe<Scalars['String']>;
  statoLavorativo?: Maybe<Scalars['Int']>;
  data_assunzione?: Maybe<Scalars['String']>;
  stipendioLordo?: Maybe<Scalars['Float']>;
  stipendioNetto?: Maybe<Scalars['Float']>;
  statoTfr?: Maybe<Scalars['Int']>;
  FondoId?: Maybe<Scalars['String']>;
  datoreLavoro?: Maybe<DatoreLavoro>;
};

export type Recapito = {
  __typename?: 'Recapito';
  Id?: Maybe<Scalars['String']>;
  clienteId?: Maybe<Scalars['String']>;
  viaDomicilio?: Maybe<Scalars['String']>;
  civicoDomicilio?: Maybe<Scalars['String']>;
  capDomicilio?: Maybe<Scalars['String']>;
  cittaDomicilio?: Maybe<Scalars['String']>;
  provinciaDomicilio?: Maybe<Scalars['String']>;
  viaResidenza?: Maybe<Scalars['String']>;
  civicoResidenza?: Maybe<Scalars['String']>;
  capResidenza?: Maybe<Scalars['String']>;
  cittaResidenza?: Maybe<Scalars['String']>;
  provinciaResidenza?: Maybe<Scalars['String']>;
  telefono1?: Maybe<Scalars['String']>;
  telefono2?: Maybe<Scalars['String']>;
  cellulare?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['String'];
  description: Scalars['String'];
  company: Scalars['String'];
  report: Scalars['String'];
  reportType: Scalars['Int'];
  reportTypeDescription: Scalars['String'];
  fileName: Scalars['String'];
};

export type ReportInputForm = {
  description: Scalars['String'];
  report: Scalars['String'];
  reportType: Scalars['Int'];
  reportTypeDescription: Scalars['String'];
  fileName: Scalars['String'];
};

export type ReportTemplate = {
  __typename?: 'ReportTemplate';
  content: Scalars['String'];
  fileName: Scalars['String'];
};

export type ReportUpdateForm = {
  description: Scalars['String'];
};

export enum Resources {
  Users = 'USERS',
  Roles = 'ROLES',
  Groups = 'GROUPS',
  Policies = 'POLICIES'
}

export type ResponseDelete = {
  __typename?: 'ResponseDelete';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  rowAffected: Scalars['Int'];
};

export type Richiesta = {
  __typename?: 'Richiesta';
  richiestaId?: Maybe<Scalars['Int']>;
  codice?: Maybe<Scalars['String']>;
  dataUltimaModifica?: Maybe<Scalars['String']>;
  utenteId?: Maybe<Scalars['String']>;
  fullnameUtente?: Maybe<Scalars['String']>;
  clienteId?: Maybe<Scalars['String']>;
  fullnameCliente?: Maybe<Scalars['String']>;
  tipoImpiego?: Maybe<Scalars['Int']>;
  importoRichiesto?: Maybe<Scalars['Float']>;
  statoRichiesta?: Maybe<Scalars['Int']>;
  statoPratica?: Maybe<Scalars['Int']>;
  documentiList?: Maybe<Array<Maybe<DocumentoRichiesta>>>;
  macroStatoRichiesta?: Maybe<Scalars['Int']>;
};

export type RichiestaList = {
  __typename?: 'RichiestaList';
  status?: Maybe<Status>;
  richiesteList?: Maybe<Array<Maybe<Richiesta>>>;
};

export type RichiestaPreventivo = {
  __typename?: 'RichiestaPreventivo';
  codiceRichiesta?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type RichiestaSingola = {
  __typename?: 'RichiestaSingola';
  id?: Maybe<Scalars['Int']>;
  codice?: Maybe<Scalars['String']>;
  utenteId?: Maybe<Scalars['String']>;
  clienteId?: Maybe<Scalars['String']>;
  stato?: Maybe<Scalars['Int']>;
  dataUltimaModifica?: Maybe<Scalars['String']>;
  dataAssunzione?: Maybe<Scalars['String']>;
  tipoImpiego?: Maybe<Scalars['Int']>;
  importoRichiesto?: Maybe<Scalars['Float']>;
  telefono?: Maybe<Scalars['String']>;
  cellulare?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  quotaCedibileId?: Maybe<Scalars['Int']>;
  documentoInpsId?: Maybe<Scalars['Int']>;
  DocumentoInfoVId?: Maybe<Scalars['String']>;
  certificatoStipendioId?: Maybe<Scalars['Int']>;
  preventivoAccettatoId?: Maybe<Scalars['String']>;
  praticaCaricataId?: Maybe<Scalars['String']>;
  macroStato?: Maybe<Scalars['Int']>;
};

export type RispostaPreventivoAccettato = {
  __typename?: 'RispostaPreventivoAccettato';
  status?: Maybe<Status>;
};

export type RispostaRinunciaRichiesta = {
  __typename?: 'RispostaRinunciaRichiesta';
  status?: Maybe<Status>;
};

export type RispostaSingolaRichiesta = {
  __typename?: 'RispostaSingolaRichiesta';
  status?: Maybe<Status>;
  listDocumenti?: Maybe<Array<Maybe<Documento>>>;
  listPreventivi?: Maybe<Array<Maybe<Preventivi>>>;
  cliente?: Maybe<Cliente>;
  richiesta?: Maybe<RichiestaSingola>;
  listNote?: Maybe<Array<Maybe<Nota>>>;
};

export type RispostaUteriorePreventivo = {
  __typename?: 'RispostaUteriorePreventivo';
  status?: Maybe<Status>;
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String'];
  description: Scalars['String'];
};

export type SingolaPratica = {
  __typename?: 'SingolaPratica';
  titolareCognome?: Maybe<Scalars['String']>;
  titolareNome?: Maybe<Scalars['String']>;
  venditoreCognome?: Maybe<Scalars['String']>;
  venditoreNome?: Maybe<Scalars['String']>;
  collocatoreCognome?: Maybe<Scalars['String']>;
  collocatoreNome?: Maybe<Scalars['String']>;
  canaleAcquisizioneDesc?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  listNote?: Maybe<Array<Maybe<Nota>>>;
  listDocumenti?: Maybe<Array<Maybe<ListDocumenti>>>;
  listImpegni?: Maybe<Array<Maybe<ListImpegni>>>;
  rapportoLavoro?: Maybe<RapportoLavoro>;
  medicoCurante?: Maybe<MedicoCurante>;
  erogazione?: Maybe<Erogazione>;
  identificazione?: Maybe<Identificazione>;
  recapito?: Maybe<Recapito>;
  cliente?: Maybe<Cliente>;
  praticaCqs?: Maybe<PraticaCqs>;
  macroStato?: Maybe<Scalars['Int']>;
};

export type SintesiMontanti = {
  __typename?: 'SintesiMontanti';
  macrostato?: Maybe<Scalars['Int']>;
  montante?: Maybe<Scalars['Float']>;
};

export type SintesiPratiche = {
  __typename?: 'SintesiPratiche';
  macrostato?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['Int']>;
};

export type SintesiRichieste = {
  __typename?: 'SintesiRichieste';
  status?: Maybe<Scalars['Int']>;
  numero?: Maybe<Scalars['Int']>;
};

export type SintesiRichiesteVsPratiche = {
  __typename?: 'SintesiRichiesteVsPratiche';
  mese?: Maybe<Scalars['String']>;
  numeroRichieste?: Maybe<Scalars['Int']>;
  numeroPratiche?: Maybe<Scalars['Int']>;
};

export type Status = {
  __typename?: 'Status';
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type StoricoStatiList = {
  __typename?: 'StoricoStatiList';
  progressivo?: Maybe<Scalars['Int']>;
  id_pratica?: Maybe<Scalars['String']>;
  id_utente?: Maybe<Scalars['String']>;
  stato_precedente?: Maybe<Scalars['Int']>;
  stato_attuale?: Maybe<Scalars['Int']>;
  data_stato?: Maybe<Scalars['String']>;
};

export type StructClusterQuantitaValore = {
  __typename?: 'StructClusterQuantitaValore';
  cluster?: Maybe<Scalars['Int']>;
  quantita?: Maybe<Scalars['Int']>;
  valore?: Maybe<Scalars['Float']>;
};

export type StructQuantitaValore = {
  __typename?: 'StructQuantitaValore';
  quantita?: Maybe<Scalars['Int']>;
  valore?: Maybe<Scalars['Float']>;
};


export type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  user: User;
  permissions: Scalars['String'];
};


export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  createdAt?: Maybe<Scalars['Time']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
};

export type UserInputWithPassword = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
};

export type AddGroupMutationVariables = Exact<{
  subject: Scalars['String'];
  role: Scalars['String'];
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { AddGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )> }
);

export type AddPolicyMutationVariables = Exact<{
  subject: Scalars['String'];
  object: Scalars['String'];
  action: Scalars['String'];
}>;


export type AddPolicyMutation = (
  { __typename?: 'Mutation' }
  & { AddPolicy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )> }
);

export type AccettaPreventivoMutationVariables = Exact<{
  input?: Maybe<AccettaPreventivo>;
}>;


export type AccettaPreventivoMutation = (
  { __typename?: 'Mutation' }
  & { accettaPreventivo: (
    { __typename?: 'RispostaPreventivoAccettato' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type CreateDocumentoMutationVariables = Exact<{
  entity?: Maybe<Entity>;
  allegati?: Maybe<Allegati>;
}>;


export type CreateDocumentoMutation = (
  { __typename?: 'Mutation' }
  & { createDocumento: (
    { __typename?: 'DocumentiListIntero' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )>, documentiList?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentoConIntero' }
      & Pick<DocumentoConIntero, 'documentoId' | 'tipoDocumento' | 'url' | 'base64' | 'fileType'>
    )>>> }
  ) }
);

export type CreateNotaMutationVariables = Exact<{
  entity?: Maybe<Entity>;
  notes?: Maybe<Array<Maybe<Note>> | Maybe<Note>>;
}>;


export type CreateNotaMutation = (
  { __typename?: 'Mutation' }
  & { createNota: (
    { __typename?: 'NotaRestituitaDaAddNota' }
    & Pick<NotaRestituitaDaAddNota, 'Id' | 'TipoNota' | 'Nota' | 'DataInserimento' | 'UtenteId' | 'UtenteNome' | 'UtenteCognome'>
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type CreateReportMutationVariables = Exact<{
  form?: Maybe<ReportInputForm>;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'description' | 'company' | 'report' | 'reportType' | 'reportTypeDescription' | 'fileName'>
  ) }
);

export type CreateRoleMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user?: Maybe<UserInputWithPassword>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & { deleteReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'description' | 'company' | 'report' | 'reportType' | 'reportTypeDescription' | 'fileName'>
  ) }
);

export type DownloadPrecontrattualeMutationVariables = Exact<{
  dati: Scalars['String'];
}>;


export type DownloadPrecontrattualeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadPrecontrattuale'>
);

export type DownloadReportTemplateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadReportTemplateMutation = (
  { __typename?: 'Mutation' }
  & { downloadReportTemplate: (
    { __typename?: 'ReportTemplate' }
    & Pick<ReportTemplate, 'content' | 'fileName'>
  ) }
);

export type ImpersonatesMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ImpersonatesMutation = (
  { __typename?: 'Mutation' }
  & { impersonates: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

export type RecoverPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecoverPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoverPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type UpdateAvatarMutationVariables = Exact<{
  email: Scalars['String'];
  avatar: Scalars['String'];
}>;


export type UpdateAvatarMutation = (
  { __typename?: 'Mutation' }
  & { updateAvatar: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateReportMutationVariables = Exact<{
  id: Scalars['String'];
  form?: Maybe<ReportUpdateForm>;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { updateReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'description' | 'company' | 'report' | 'reportType' | 'reportTypeDescription' | 'fileName'>
  ) }
);

export type UpdateSelfPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateSelfPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateSelfPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  user?: Maybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPassword: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type ChiediUlteriorePreventivoQueryVariables = Exact<{
  richiestaId?: Maybe<Scalars['Int']>;
  nota?: Maybe<Scalars['String']>;
}>;


export type ChiediUlteriorePreventivoQuery = (
  { __typename?: 'Query' }
  & { chiediUlteriorePreventivo: (
    { __typename?: 'RispostaUteriorePreventivo' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type DatiSingolaPraticaQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
}>;


export type DatiSingolaPraticaQuery = (
  { __typename?: 'Query' }
  & { datiSingolaPratica: (
    { __typename?: 'SingolaPratica' }
    & Pick<SingolaPratica, 'titolareCognome' | 'titolareNome' | 'venditoreCognome' | 'venditoreNome' | 'collocatoreCognome' | 'collocatoreNome' | 'canaleAcquisizioneDesc' | 'macroStato'>
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )>, listNote?: Maybe<Array<Maybe<(
      { __typename?: 'Nota' }
      & Pick<Nota, 'Id' | 'TipoNota' | 'Nota' | 'DataInserimento' | 'UtenteId' | 'UtenteNome' | 'UtenteCognome'>
      & { status?: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'status' | 'errorMessage'>
      )> }
    )>>>, listDocumenti?: Maybe<Array<Maybe<(
      { __typename?: 'ListDocumenti' }
      & Pick<ListDocumenti, 'documentoId' | 'descrizione' | 'tipoDocumento' | 'url'>
    )>>>, listImpegni?: Maybe<Array<Maybe<(
      { __typename?: 'ListImpegni' }
      & Pick<ListImpegni, 'Id' | 'bancaId' | 'descrizioneBanca' | 'rata' | 'durata' | 'dataDecorrenza' | 'rateResidue' | 'estinzione' | 'tipoImpegno' | 'importoEstinzione' | 'documentoId' | 'statoEstinzione'>
    )>>>, rapportoLavoro?: Maybe<(
      { __typename?: 'RapportoLavoro' }
      & Pick<RapportoLavoro, 'Id' | 'praticaId' | 'datoreLavoroId' | 'statoLavorativo' | 'data_assunzione' | 'stipendioLordo' | 'stipendioNetto' | 'statoTfr' | 'FondoId'>
      & { datoreLavoro?: Maybe<(
        { __typename?: 'DatoreLavoro' }
        & Pick<DatoreLavoro, 'Id' | 'ragioneSociale' | 'codiceFiscale' | 'partitaIva' | 'codiceAtc' | 'via' | 'civico' | 'cap' | 'citta' | 'provincia' | 'telefono1' | 'telefono2' | 'pec' | 'eMail' | 'personaRiferimento' | 'active' | 'dataInattivazione'>
      )> }
    )>, medicoCurante?: Maybe<(
      { __typename?: 'MedicoCurante' }
      & Pick<MedicoCurante, 'praticaId' | 'cognome' | 'nome' | 'citta' | 'provincia' | 'telefono' | 'eMail' | 'indirizzo'>
    )>, erogazione?: Maybe<(
      { __typename?: 'Erogazione' }
      & Pick<Erogazione, 'Id' | 'praticaId' | 'iban' | 'bancaId' | 'desrizioneBanca' | 'assegno'>
    )>, identificazione?: Maybe<(
      { __typename?: 'Identificazione' }
      & Pick<Identificazione, 'Id' | 'tipoDocumento' | 'numeroDocumento' | 'dataRilascio' | 'dataScadenza' | 'enteRilascio' | 'luogoRilascio'>
    )>, recapito?: Maybe<(
      { __typename?: 'Recapito' }
      & Pick<Recapito, 'Id' | 'clienteId' | 'viaDomicilio' | 'civicoDomicilio' | 'capDomicilio' | 'cittaDomicilio' | 'provinciaDomicilio' | 'viaResidenza' | 'civicoResidenza' | 'capResidenza' | 'cittaResidenza' | 'provinciaResidenza' | 'telefono1' | 'telefono2' | 'cellulare' | 'eMail'>
    )>, cliente?: Maybe<(
      { __typename?: 'Cliente' }
      & Pick<Cliente, 'Id' | 'cognome' | 'nome' | 'sesso' | 'DataNascita' | 'cittaNascita' | 'provinciaNascita' | 'codiceFiscale'>
    )>, praticaCqs?: Maybe<(
      { __typename?: 'PraticaCqs' }
      & Pick<PraticaCqs, 'id' | 'codice' | 'stato' | 'segnalazione' | 'clienteId' | 'preventivoId' | 'titolareId' | 'venditoreId' | 'collocatoreId' | 'canaleVenditaId' | 'dataSalvataggio' | 'dataDecorrenza' | 'dataApprovazioneBancaErogante' | 'dataNotifica' | 'notificaScandenzaSended' | 'tipoNotifica' | 'numeroRaccomandataNotifica' | 'conteggioEstintivo' | 'dataRichiestaPolizza' | 'dataEmissionePolizza' | 'compagniaAssicurativaPolizza' | 'statoAssicurazione' | 'dataRichiestaInBancaPolizza' | 'speseVive' | 'speseViveConRitenuta' | 'importoAcconto' | 'statoAcconto' | 'dataUltimaModificaAcconto' | 'importoSecondoAcconto' | 'statoSecondoAcconto' | 'dataUltimaModificaSecondoAcconto' | 'documentiFirmati' | 'dataFirmaDocumenti' | 'dataRicezioneDocumentiFirmati' | 'statoPraticaINPS' | 'dtInvioNotificaScandeza'>
    )> }
  ) }
);

export type DatiSingolaRichiestaQueryVariables = Exact<{
  richiestaId?: Maybe<Scalars['Int']>;
}>;


export type DatiSingolaRichiestaQuery = (
  { __typename?: 'Query' }
  & { datiSingolaRichiesta: (
    { __typename?: 'RispostaSingolaRichiesta' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )>, listDocumenti?: Maybe<Array<Maybe<(
      { __typename?: 'Documento' }
      & Pick<Documento, 'documentoId' | 'tipoDocumento' | 'url' | 'descrizione'>
    )>>>, listPreventivi?: Maybe<Array<Maybe<(
      { __typename?: 'Preventivi' }
      & Pick<Preventivi, 'id' | 'codice' | 'collaboratoreId' | 'dataUltimoSalvataggio' | 'cognome' | 'nome' | 'sesso' | 'dataNascita' | 'tipoProdotto' | 'tipoImpiego' | 'dataAssunzione' | 'dataDecorrenza' | 'rata' | 'durata' | 'rinnovo' | 'deltaMontante' | 'percentualeMargineCollaboratore' | 'importoMargineCollaboratore' | 'bancaEroganteId' | 'montanteLordo' | 'costiBancari' | 'assicurazioni' | 'speseRegistro' | 'altreSpese' | 'taeg' | 'teg' | 'tan' | 'nettoErogato' | 'assicurazioneId' | 'descrizioneAssicurazione' | 'descrizioneBancaErogante' | 'descrizioneProdotto' | 'importoOverPrestitoFelice' | 'percentualeOverPrestitoFelice' | 'bossId' | 'importoOverBoss' | 'percentualeOverBoss'>
    )>>>, cliente?: Maybe<(
      { __typename?: 'Cliente' }
      & Pick<Cliente, 'Id' | 'cognome' | 'nome' | 'sesso' | 'DataNascita' | 'cittaNascita' | 'provinciaNascita' | 'codiceFiscale'>
    )>, richiesta?: Maybe<(
      { __typename?: 'RichiestaSingola' }
      & Pick<RichiestaSingola, 'id' | 'codice' | 'utenteId' | 'clienteId' | 'stato' | 'dataUltimaModifica' | 'dataAssunzione' | 'tipoImpiego' | 'importoRichiesto' | 'telefono' | 'cellulare' | 'eMail' | 'quotaCedibileId' | 'documentoInpsId' | 'DocumentoInfoVId' | 'certificatoStipendioId' | 'preventivoAccettatoId' | 'praticaCaricataId' | 'macroStato'>
    )>, listNote?: Maybe<Array<Maybe<(
      { __typename?: 'Nota' }
      & Pick<Nota, 'Id' | 'TipoNota' | 'Nota' | 'DataInserimento' | 'UtenteId' | 'UtenteNome' | 'UtenteCognome'>
      & { status?: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'status' | 'errorMessage'>
      )> }
    )>>> }
  ) }
);

export type DownloadDocumentoQueryVariables = Exact<{
  documentoId?: Maybe<Scalars['String']>;
}>;


export type DownloadDocumentoQuery = (
  { __typename?: 'Query' }
  & { downloadDocumento: (
    { __typename?: 'DownloadDoc' }
    & { documentoConIntero?: Maybe<(
      { __typename?: 'DocumentoConIntero' }
      & Pick<DocumentoConIntero, 'documentoId' | 'tipoDocumento' | 'url' | 'base64' | 'fileType'>
    )>, status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'subject' | 'role' | 'domain'>
  )>>> }
);

export type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires' | 'permissions'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
    ) }
  ) }
);

export type GetPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPoliciesQuery = (
  { __typename?: 'Query' }
  & { getPolicies?: Maybe<Array<Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'subject' | 'domain' | 'object' | 'action'>
  )>>> }
);

export type LoggedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedUserQuery = (
  { __typename?: 'Query' }
  & { loggedUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type OttieniConfigurazioniQueryVariables = Exact<{ [key: string]: never; }>;


export type OttieniConfigurazioniQuery = (
  { __typename?: 'Query' }
  & { ottieniConfigurazioni?: Maybe<(
    { __typename?: 'Configurazioni' }
    & Pick<Configurazioni, 'durata'>
    & { prodotti?: Maybe<Array<Maybe<(
      { __typename?: 'Prodotti' }
      & Pick<Prodotti, 'codiceBancaErogante' | 'codiceProdotto' | 'descrizione'>
      & { dettagli?: Maybe<Array<Maybe<(
        { __typename?: 'Dettagli' }
        & Pick<Dettagli, 'codice' | 'descrizione'>
      )>>> }
    )>>>, items?: Maybe<Array<Maybe<(
      { __typename?: 'Items' }
      & Pick<Items, 'codice' | 'descrizione' | 'tipo'>
    )>>> }
  )> }
);

export type OttieniDashboardCommissionaleQueryVariables = Exact<{ [key: string]: never; }>;


export type OttieniDashboardCommissionaleQuery = (
  { __typename?: 'Query' }
  & { ottieniDashboardCommissionale?: Maybe<(
    { __typename?: 'DashboardCommissionale' }
    & { listPraticheMaturateFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListStruct' }
      & Pick<ListStruct, 'filialeId' | 'quantita' | 'valore'>
    )>>>, listMontanteLordoMaturatoFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListStruct' }
      & Pick<ListStruct, 'filialeId' | 'quantita' | 'valore'>
    )>>>, praticheMaturateSocieta?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, montanteLordoMaturatoSocieta?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, listPraticheTransitorieFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListStruct' }
      & Pick<ListStruct, 'filialeId' | 'quantita' | 'valore'>
    )>>>, listMontanteLordoTransitorioFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListStruct' }
      & Pick<ListStruct, 'filialeId' | 'quantita' | 'valore'>
    )>>>, praticheTransitorioSocieta?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, montanteLordoTransitorioSocieta?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, listPratichePotenzialiFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListStruct' }
      & Pick<ListStruct, 'filialeId' | 'quantita' | 'valore'>
    )>>>, listMontanteLordoPotenzialeFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListStruct' }
      & Pick<ListStruct, 'filialeId' | 'quantita' | 'valore'>
    )>>>, pratichePotenzialeSocieta?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, montanteLordoPotenzialeSocieta?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, listPraticheMaturateMeseFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListPraticheMaturateMeseFiliali' }
      & Pick<ListPraticheMaturateMeseFiliali, 'filialeId'>
      & { listValoreMese?: Maybe<Array<Maybe<(
        { __typename?: 'MeseValore' }
        & Pick<MeseValore, 'mese' | 'valore'>
      )>>> }
    )>>>, listPraticheMaturateMeseSocietaMediazione?: Maybe<Array<Maybe<(
      { __typename?: 'MeseValore' }
      & Pick<MeseValore, 'mese' | 'valore'>
    )>>>, listPraticheMaturateClusterFiliali?: Maybe<Array<Maybe<(
      { __typename?: 'ListPraticheMaturateClusterFiliale' }
      & Pick<ListPraticheMaturateClusterFiliale, 'filialeId'>
      & { listValoreCluster?: Maybe<Array<Maybe<(
        { __typename?: 'StructClusterQuantitaValore' }
        & Pick<StructClusterQuantitaValore, 'cluster' | 'quantita' | 'valore'>
      )>>> }
    )>>>, listPraticheMaturateClusterSocieta?: Maybe<Array<Maybe<(
      { __typename?: 'StructClusterQuantitaValore' }
      & Pick<StructClusterQuantitaValore, 'cluster' | 'quantita' | 'valore'>
    )>>>, status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )>, listPraticheMaturateClusterUtente?: Maybe<Array<Maybe<(
      { __typename?: 'StructClusterQuantitaValore' }
      & Pick<StructClusterQuantitaValore, 'cluster' | 'quantita' | 'valore'>
    )>>>, listPraticheMaturateMeseUtente?: Maybe<Array<Maybe<(
      { __typename?: 'MeseValore' }
      & Pick<MeseValore, 'mese' | 'valore'>
    )>>>, montanteLordoPotenzialeUtente?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, pratichePotenzialiUtente?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, montanteLordoTransitorieUtente?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, praticheTransitorieUtente?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, montanteLordoMaturatoUtente?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )>, praticheMaturateUtente?: Maybe<(
      { __typename?: 'StructQuantitaValore' }
      & Pick<StructQuantitaValore, 'quantita' | 'valore'>
    )> }
  )> }
);

export type OttieniDashboardGeneraleQueryVariables = Exact<{ [key: string]: never; }>;


export type OttieniDashboardGeneraleQuery = (
  { __typename?: 'Query' }
  & { ottieniDashboardGenerale?: Maybe<(
    { __typename?: 'DashboardGenerale' }
    & Pick<DashboardGenerale, 'dataUltimaElaborazione'>
    & { elencoUltimeNote?: Maybe<Array<Maybe<(
      { __typename?: 'ElencoUltimeNote' }
      & Pick<ElencoUltimeNote, 'notaId' | 'dataInserimento' | 'codice' | 'tipoNota' | 'mittenteId' | 'fullnameMittente' | 'testo'>
    )>>>, elencoUltimePratiche?: Maybe<Array<Maybe<(
      { __typename?: 'ElencoUltimePratiche' }
      & Pick<ElencoUltimePratiche, 'praticaId' | 'dataUltimaModifica' | 'cognomeCliente' | 'nomeCliente' | 'montanteLordo'>
    )>>>, sintesiMontanti?: Maybe<Array<Maybe<(
      { __typename?: 'SintesiMontanti' }
      & Pick<SintesiMontanti, 'macrostato' | 'montante'>
    )>>>, sintesiRichiesteVsPratiche?: Maybe<Array<Maybe<(
      { __typename?: 'SintesiRichiesteVsPratiche' }
      & Pick<SintesiRichiesteVsPratiche, 'mese' | 'numeroRichieste' | 'numeroPratiche'>
    )>>>, sintesiPratiche?: Maybe<Array<Maybe<(
      { __typename?: 'SintesiPratiche' }
      & Pick<SintesiPratiche, 'macrostato' | 'numero'>
    )>>>, sintesiRichieste?: Maybe<Array<Maybe<(
      { __typename?: 'SintesiRichieste' }
      & Pick<SintesiRichieste, 'status' | 'numero'>
    )>>>, status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  )> }
);

export type OttieniPraticheQueryVariables = Exact<{
  filters?: Maybe<Filters>;
}>;


export type OttieniPraticheQuery = (
  { __typename?: 'Query' }
  & { ottieniPratiche?: Maybe<(
    { __typename?: 'PraticheList' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )>, praticheList?: Maybe<Array<Maybe<(
      { __typename?: 'Pratica' }
      & Pick<Pratica, 'id_pratica' | 'data_salvataggio' | 'codice_pratica' | 'stato' | 'progressivo' | 'segnalazione' | 'id_ute_backoffice' | 'id_cliente' | 'id_preventivo' | 'id_utente' | 'id_collocatore' | 'data_notifica' | 'tipo_notifica' | 'numero_raccomandata' | 'conteggio_estintivo' | 'data_richiesta_polizza' | 'data_emissione_polizza' | 'compagnia_assicurativa_polizza' | 'decorrenza' | 'spesevive' | 'ImportoAcconto' | 'StatoAcconto' | 'SospesaPreAccettazione' | 'documenti_firmati' | 'data_firma' | 'statoPraticaINPS' | 'DataUltModAcc' | 'ImportoAcconto1' | 'StatoAcconto1' | 'DataUltModAcc1' | 'idVenditore' | 'notificaScandenzaSended' | 'dtInvioNotificaScandeza' | 'IdCanaleVendita' | 'statoAssicurazione' | 'dtRichPolizzaBanca' | 'dtRicDocs' | 'DataApprovazioneBancaErogante'>
      & { documentiList?: Maybe<Array<Maybe<(
        { __typename?: 'Documento' }
        & Pick<Documento, 'documentoId' | 'tipoDocumento' | 'url' | 'descrizione'>
      )>>>, storicoStatiList?: Maybe<Array<Maybe<(
        { __typename?: 'StoricoStatiList' }
        & Pick<StoricoStatiList, 'progressivo' | 'id_pratica' | 'id_utente' | 'stato_precedente' | 'stato_attuale' | 'data_stato'>
      )>>>, noteList?: Maybe<Array<Maybe<(
        { __typename?: 'NoteList' }
        & Pick<NoteList, 'Id' | 'TipoNota' | 'Nota' | 'DataInserimento' | 'UtenteId'>
      )>>>, preventivo?: Maybe<(
        { __typename?: 'Preventivo' }
        & Pick<Preventivo, 'TipoImpiego' | 'TipoProdotto' | 'DataUltimaRata' | 'IsRinnovoIbl' | 'FullName' | 'id_preventivo' | 'id_cliente' | 'cognome' | 'nome' | 'sesso' | 'data_nascita' | 'data_assunzione' | 'tipo_impiego_bis' | 'tipo_qualifica' | 'data_decorrenza' | 'montante_lordo' | 'costi_bancari' | 'assicurazioni' | 'spese_registro' | 'altre_spese' | 'margine_percentuale' | 'importo_margine' | 'importo_rata' | 'durata_impegno' | 'tipo_prodotto_bis' | 'rinnovo' | 'taeg' | 'teg' | 'tan' | 'netto_erogato' | 'id_assicurazione' | 'data_salvataggio' | 'id_utentesessione' | 'nome_prodotto' | 'descrizione_assicurazione' | 'descrizione_banca' | 'descrizione_prodotto' | 'importo_over' | 'over_ute' | 'idbanca' | 'decorrenza' | 'netto_erogato_orig' | 'delta_provvigioni' | 'progressivo' | 'pdfFile' | 'pspdfFile' | 'deltaMontante_lordo' | 'BancaEroganteItem' | 'ClienteItem' | 'UtenteItem' | 'PreventivoOverItem' | 'DocumentoLinkItems' | 'TrattativaItems' | 'PraticaItems'>
      )> }
    )>>> }
  )> }
);

export type OttieniRichiesteQueryVariables = Exact<{
  filters?: Maybe<FiltersRic>;
}>;


export type OttieniRichiesteQuery = (
  { __typename?: 'Query' }
  & { ottieniRichieste?: Maybe<(
    { __typename?: 'RichiestaList' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )>, richiesteList?: Maybe<Array<Maybe<(
      { __typename?: 'Richiesta' }
      & Pick<Richiesta, 'richiestaId' | 'codice' | 'dataUltimaModifica' | 'utenteId' | 'fullnameUtente' | 'clienteId' | 'fullnameCliente' | 'tipoImpiego' | 'importoRichiesto' | 'statoRichiesta' | 'statoPratica' | 'macroStatoRichiesta'>
      & { documentiList?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentoRichiesta' }
        & Pick<DocumentoRichiesta, 'documentoId' | 'tipoDocumento' | 'descrizione' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

export type ReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'description' | 'company' | 'report' | 'reportType' | 'reportTypeDescription' | 'fileName'>
  ) }
);

export type ReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { reports?: Maybe<Array<Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'description' | 'company' | 'report' | 'reportType' | 'reportTypeDescription' | 'fileName'>
  )>>> }
);

export type RichediPreventiviQueryVariables = Exact<{
  input?: Maybe<InputRichiestaPreventivi>;
}>;


export type RichediPreventiviQuery = (
  { __typename?: 'Query' }
  & { richediPreventivi: (
    { __typename?: 'RichiestaPreventivo' }
    & Pick<RichiestaPreventivo, 'codiceRichiesta'>
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type RichiediPreventiviConDocumentoSingoloQueryVariables = Exact<{
  input?: Maybe<InputRichiestaPreventiviConDocumentoSingolo>;
}>;


export type RichiediPreventiviConDocumentoSingoloQuery = (
  { __typename?: 'Query' }
  & { richiediPreventiviConDocumentoSingolo: (
    { __typename?: 'RichiestaPreventivo' }
    & Pick<RichiestaPreventivo, 'codiceRichiesta'>
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type RinunciaRichiestaQueryVariables = Exact<{
  richiestaId?: Maybe<Scalars['Int']>;
  nota?: Maybe<Scalars['String']>;
}>;


export type RinunciaRichiestaQuery = (
  { __typename?: 'Query' }
  & { rinunciaRichiesta: (
    { __typename?: 'RispostaRinunciaRichiesta' }
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'status' | 'errorMessage'>
    )> }
  ) }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'description'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  ) }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'firstName' | 'lastName' | 'email' | 'avatar' | 'createdAt'>
  )>>> }
);


export const AddGroupDocument = gql`
    mutation AddGroup($subject: String!, $role: String!) {
  AddGroup(subject: $subject, role: $role) {
    subject
    role
    domain
  }
}
    `;
export const AddPolicyDocument = gql`
    mutation AddPolicy($subject: String!, $object: String!, $action: String!) {
  AddPolicy(subject: $subject, object: $object, action: $action) {
    subject
    domain
    object
    action
  }
}
    `;
export const AccettaPreventivoDocument = gql`
    mutation accettaPreventivo($input: AccettaPreventivo) {
  accettaPreventivo(input: $input) {
    status {
      status
      errorMessage
    }
  }
}
    `;
export const CreateDocumentoDocument = gql`
    mutation createDocumento($entity: Entity, $allegati: Allegati) {
  createDocumento(entity: $entity, allegati: $allegati) {
    status {
      status
      errorMessage
    }
    documentiList {
      documentoId
      tipoDocumento
      url
      base64
      fileType
    }
  }
}
    `;
export const CreateNotaDocument = gql`
    mutation createNota($entity: Entity, $notes: [Note]) {
  createNota(entity: $entity, notes: $notes) {
    Id
    TipoNota
    Nota
    DataInserimento
    UtenteId
    status {
      status
      errorMessage
    }
    UtenteNome
    UtenteCognome
  }
}
    `;
export const CreateReportDocument = gql`
    mutation createReport($form: ReportInputForm) {
  createReport(form: $form) {
    id
    description
    company
    report
    reportType
    reportTypeDescription
    fileName
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation createRole($name: String!, $description: String!) {
  createRole(name: $name, description: $description) {
    name
    description
  }
}
    `;
export const CreateUserDocument = gql`
    mutation createUser($user: UserInputWithPassword) {
  createUser(user: $user) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const DeleteReportDocument = gql`
    mutation deleteReport($id: String!) {
  deleteReport(id: $id) {
    id
    description
    company
    report
    reportType
    reportTypeDescription
    fileName
  }
}
    `;
export const DownloadPrecontrattualeDocument = gql`
    mutation downloadPrecontrattuale($dati: String!) {
  downloadPrecontrattuale(dati: $dati)
}
    `;
export const DownloadReportTemplateDocument = gql`
    mutation downloadReportTemplate($id: String!) {
  downloadReportTemplate(id: $id) {
    content
    fileName
  }
}
    `;
export const ImpersonatesDocument = gql`
    mutation impersonates($email: String!) {
  impersonates(email: $email) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
export const RecoverPasswordDocument = gql`
    mutation recoverPassword($email: String!) {
  recoverPassword(email: $email)
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $code: String!, $newPassword: String!) {
  resetPassword(email: $email, code: $code, newPassword: $newPassword) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($email: String!, $avatar: String!) {
  updateAvatar(email: $email, avatar: $avatar) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateReportDocument = gql`
    mutation updateReport($id: String!, $form: ReportUpdateForm) {
  updateReport(id: $id, form: $form) {
    id
    description
    company
    report
    reportType
    reportTypeDescription
    fileName
  }
}
    `;
export const UpdateSelfPasswordDocument = gql`
    mutation updateSelfPassword($email: String!, $oldPassword: String!, $newPassword: String!) {
  updateSelfPassword(
    email: $email
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($email: String!, $user: UpdateUserInput) {
  updateUser(email: $email, user: $user) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($email: String!, $newPassword: String!) {
  updateUserPassword(email: $email, newPassword: $newPassword) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const ChiediUlteriorePreventivoDocument = gql`
    query chiediUlteriorePreventivo($richiestaId: Int, $nota: String) {
  chiediUlteriorePreventivo(richiestaId: $richiestaId, nota: $nota) {
    status {
      status
      errorMessage
    }
  }
}
    `;
export const DatiSingolaPraticaDocument = gql`
    query datiSingolaPratica($id: String, $nota: String) {
  datiSingolaPratica(id: $id, nota: $nota) {
    titolareCognome
    titolareNome
    venditoreCognome
    venditoreNome
    collocatoreCognome
    collocatoreNome
    canaleAcquisizioneDesc
    status {
      status
      errorMessage
    }
    listNote {
      Id
      TipoNota
      Nota
      DataInserimento
      UtenteId
      status {
        status
        errorMessage
      }
      UtenteNome
      UtenteCognome
    }
    listDocumenti {
      documentoId
      descrizione
      tipoDocumento
      url
    }
    listImpegni {
      Id
      bancaId
      descrizioneBanca
      rata
      durata
      dataDecorrenza
      rateResidue
      estinzione
      tipoImpegno
      importoEstinzione
      documentoId
      statoEstinzione
    }
    rapportoLavoro {
      Id
      praticaId
      datoreLavoroId
      statoLavorativo
      data_assunzione
      stipendioLordo
      stipendioNetto
      statoTfr
      FondoId
      datoreLavoro {
        Id
        ragioneSociale
        codiceFiscale
        partitaIva
        codiceAtc
        via
        civico
        cap
        citta
        provincia
        telefono1
        telefono2
        pec
        eMail
        personaRiferimento
        active
        dataInattivazione
      }
    }
    medicoCurante {
      praticaId
      cognome
      nome
      citta
      provincia
      telefono
      eMail
      indirizzo
    }
    erogazione {
      Id
      praticaId
      iban
      bancaId
      desrizioneBanca
      assegno
    }
    identificazione {
      Id
      tipoDocumento
      numeroDocumento
      dataRilascio
      dataScadenza
      enteRilascio
      luogoRilascio
    }
    recapito {
      Id
      clienteId
      viaDomicilio
      civicoDomicilio
      capDomicilio
      cittaDomicilio
      provinciaDomicilio
      viaResidenza
      civicoResidenza
      capResidenza
      cittaResidenza
      provinciaResidenza
      telefono1
      telefono2
      cellulare
      eMail
    }
    cliente {
      Id
      cognome
      nome
      sesso
      DataNascita
      cittaNascita
      provinciaNascita
      codiceFiscale
    }
    praticaCqs {
      id
      codice
      stato
      segnalazione
      clienteId
      preventivoId
      titolareId
      venditoreId
      collocatoreId
      canaleVenditaId
      dataSalvataggio
      dataDecorrenza
      dataApprovazioneBancaErogante
      dataNotifica
      notificaScandenzaSended
      tipoNotifica
      numeroRaccomandataNotifica
      conteggioEstintivo
      dataRichiestaPolizza
      dataEmissionePolizza
      compagniaAssicurativaPolizza
      statoAssicurazione
      dataRichiestaInBancaPolizza
      speseVive
      speseViveConRitenuta
      importoAcconto
      statoAcconto
      dataUltimaModificaAcconto
      importoSecondoAcconto
      statoSecondoAcconto
      dataUltimaModificaSecondoAcconto
      documentiFirmati
      dataFirmaDocumenti
      dataRicezioneDocumentiFirmati
      statoPraticaINPS
      dtInvioNotificaScandeza
    }
    macroStato
  }
}
    `;
export const DatiSingolaRichiestaDocument = gql`
    query datiSingolaRichiesta($richiestaId: Int) {
  datiSingolaRichiesta(richiestaId: $richiestaId) {
    status {
      status
      errorMessage
    }
    listDocumenti {
      documentoId
      tipoDocumento
      url
      descrizione
    }
    listPreventivi {
      id
      codice
      collaboratoreId
      dataUltimoSalvataggio
      cognome
      nome
      sesso
      dataNascita
      tipoProdotto
      tipoImpiego
      dataAssunzione
      dataDecorrenza
      rata
      durata
      rinnovo
      deltaMontante
      percentualeMargineCollaboratore
      importoMargineCollaboratore
      bancaEroganteId
      montanteLordo
      costiBancari
      assicurazioni
      speseRegistro
      altreSpese
      taeg
      teg
      tan
      nettoErogato
      assicurazioneId
      descrizioneAssicurazione
      descrizioneBancaErogante
      descrizioneProdotto
      importoOverPrestitoFelice
      percentualeOverPrestitoFelice
      bossId
      importoOverBoss
      percentualeOverBoss
    }
    cliente {
      Id
      cognome
      nome
      sesso
      DataNascita
      cittaNascita
      provinciaNascita
      codiceFiscale
    }
    richiesta {
      id
      codice
      utenteId
      clienteId
      stato
      dataUltimaModifica
      dataAssunzione
      tipoImpiego
      importoRichiesto
      telefono
      cellulare
      eMail
      quotaCedibileId
      documentoInpsId
      DocumentoInfoVId
      certificatoStipendioId
      preventivoAccettatoId
      praticaCaricataId
      macroStato
    }
    listNote {
      Id
      TipoNota
      Nota
      DataInserimento
      UtenteId
      status {
        status
        errorMessage
      }
      UtenteNome
      UtenteCognome
    }
  }
}
    `;
export const DownloadDocumentoDocument = gql`
    query downloadDocumento($documentoId: String) {
  downloadDocumento(documentoId: $documentoId) {
    documentoConIntero {
      documentoId
      tipoDocumento
      url
      base64
      fileType
    }
    status {
      status
      errorMessage
    }
  }
}
    `;
export const GetGroupsDocument = gql`
    query getGroups {
  getGroups {
    subject
    role
    domain
  }
}
    `;
export const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    user {
      firstName
      lastName
      email
      avatar
      createdAt
    }
    permissions
  }
}
    `;
export const GetPoliciesDocument = gql`
    query getPolicies {
  getPolicies {
    subject
    domain
    object
    action
  }
}
    `;
export const LoggedUserDocument = gql`
    query loggedUser {
  loggedUser {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const OttieniConfigurazioniDocument = gql`
    query ottieniConfigurazioni {
  ottieniConfigurazioni {
    durata
    prodotti {
      codiceBancaErogante
      codiceProdotto
      descrizione
      dettagli {
        codice
        descrizione
      }
    }
    items {
      codice
      descrizione
      tipo
    }
  }
}
    `;
export const OttieniDashboardCommissionaleDocument = gql`
    query ottieniDashboardCommissionale {
  ottieniDashboardCommissionale {
    listPraticheMaturateFiliali {
      filialeId
      quantita
      valore
    }
    listMontanteLordoMaturatoFiliali {
      filialeId
      quantita
      valore
    }
    praticheMaturateSocieta {
      quantita
      valore
    }
    montanteLordoMaturatoSocieta {
      quantita
      valore
    }
    listPraticheTransitorieFiliali {
      filialeId
      quantita
      valore
    }
    listMontanteLordoTransitorioFiliali {
      filialeId
      quantita
      valore
    }
    praticheTransitorioSocieta {
      quantita
      valore
    }
    montanteLordoTransitorioSocieta {
      quantita
      valore
    }
    listPratichePotenzialiFiliali {
      filialeId
      quantita
      valore
    }
    listMontanteLordoPotenzialeFiliali {
      filialeId
      quantita
      valore
    }
    pratichePotenzialeSocieta {
      quantita
      valore
    }
    montanteLordoPotenzialeSocieta {
      quantita
      valore
    }
    listPraticheMaturateMeseFiliali {
      filialeId
      listValoreMese {
        mese
        valore
      }
    }
    listPraticheMaturateMeseSocietaMediazione {
      mese
      valore
    }
    listPraticheMaturateClusterFiliali {
      filialeId
      listValoreCluster {
        cluster
        quantita
        valore
      }
    }
    listPraticheMaturateClusterSocieta {
      cluster
      quantita
      valore
    }
    status {
      status
      errorMessage
    }
    listPraticheMaturateClusterUtente {
      cluster
      quantita
      valore
    }
    listPraticheMaturateMeseUtente {
      mese
      valore
    }
    montanteLordoPotenzialeUtente {
      quantita
      valore
    }
    pratichePotenzialiUtente {
      quantita
      valore
    }
    montanteLordoTransitorieUtente {
      quantita
      valore
    }
    praticheTransitorieUtente {
      quantita
      valore
    }
    montanteLordoMaturatoUtente {
      quantita
      valore
    }
    praticheMaturateUtente {
      quantita
      valore
    }
  }
}
    `;
export const OttieniDashboardGeneraleDocument = gql`
    query ottieniDashboardGenerale {
  ottieniDashboardGenerale {
    dataUltimaElaborazione
    elencoUltimeNote {
      notaId
      dataInserimento
      codice
      tipoNota
      mittenteId
      fullnameMittente
      testo
    }
    elencoUltimePratiche {
      praticaId
      dataUltimaModifica
      cognomeCliente
      nomeCliente
      montanteLordo
    }
    sintesiMontanti {
      macrostato
      montante
    }
    sintesiRichiesteVsPratiche {
      mese
      numeroRichieste
      numeroPratiche
    }
    sintesiPratiche {
      macrostato
      numero
    }
    sintesiRichieste {
      status
      numero
    }
    status {
      status
      errorMessage
    }
  }
}
    `;
export const OttieniPraticheDocument = gql`
    query ottieniPratiche($filters: Filters) {
  ottieniPratiche(filters: $filters) {
    status {
      status
      errorMessage
    }
    praticheList {
      id_pratica
      data_salvataggio
      codice_pratica
      stato
      progressivo
      segnalazione
      id_ute_backoffice
      id_cliente
      id_preventivo
      id_utente
      id_collocatore
      data_notifica
      tipo_notifica
      numero_raccomandata
      conteggio_estintivo
      data_richiesta_polizza
      data_emissione_polizza
      compagnia_assicurativa_polizza
      decorrenza
      spesevive
      ImportoAcconto
      StatoAcconto
      SospesaPreAccettazione
      documenti_firmati
      data_firma
      statoPraticaINPS
      DataUltModAcc
      ImportoAcconto1
      StatoAcconto1
      DataUltModAcc1
      idVenditore
      notificaScandenzaSended
      dtInvioNotificaScandeza
      IdCanaleVendita
      statoAssicurazione
      dtRichPolizzaBanca
      dtRicDocs
      DataApprovazioneBancaErogante
      documentiList {
        documentoId
        tipoDocumento
        url
        descrizione
      }
      storicoStatiList {
        progressivo
        id_pratica
        id_utente
        stato_precedente
        stato_attuale
        data_stato
      }
      noteList {
        Id
        TipoNota
        Nota
        DataInserimento
        UtenteId
      }
      preventivo {
        TipoImpiego
        TipoProdotto
        DataUltimaRata
        IsRinnovoIbl
        FullName
        id_preventivo
        id_cliente
        cognome
        nome
        sesso
        data_nascita
        data_assunzione
        tipo_impiego_bis
        tipo_qualifica
        data_decorrenza
        montante_lordo
        costi_bancari
        assicurazioni
        spese_registro
        altre_spese
        margine_percentuale
        importo_margine
        importo_rata
        durata_impegno
        tipo_prodotto_bis
        rinnovo
        taeg
        teg
        tan
        netto_erogato
        id_assicurazione
        data_salvataggio
        id_utentesessione
        nome_prodotto
        descrizione_assicurazione
        descrizione_banca
        descrizione_prodotto
        importo_over
        over_ute
        idbanca
        decorrenza
        netto_erogato_orig
        delta_provvigioni
        progressivo
        pdfFile
        pspdfFile
        deltaMontante_lordo
        BancaEroganteItem
        ClienteItem
        UtenteItem
        PreventivoOverItem
        DocumentoLinkItems
        TrattativaItems
        PraticaItems
      }
    }
  }
}
    `;
export const OttieniRichiesteDocument = gql`
    query ottieniRichieste($filters: FiltersRic) {
  ottieniRichieste(filters: $filters) {
    status {
      status
      errorMessage
    }
    richiesteList {
      richiestaId
      codice
      dataUltimaModifica
      utenteId
      fullnameUtente
      clienteId
      fullnameCliente
      tipoImpiego
      importoRichiesto
      statoRichiesta
      statoPratica
      documentiList {
        documentoId
        tipoDocumento
        descrizione
        url
      }
      macroStatoRichiesta
    }
  }
}
    `;
export const PingDocument = gql`
    query ping {
  ping
}
    `;
export const ReportDocument = gql`
    query report($id: String!) {
  report(id: $id) {
    id
    description
    company
    report
    reportType
    reportTypeDescription
    fileName
  }
}
    `;
export const ReportsDocument = gql`
    query reports {
  reports {
    id
    description
    company
    report
    reportType
    reportTypeDescription
    fileName
  }
}
    `;
export const RichediPreventiviDocument = gql`
    query richediPreventivi($input: InputRichiestaPreventivi) {
  richediPreventivi(input: $input) {
    codiceRichiesta
    status {
      status
      errorMessage
    }
  }
}
    `;
export const RichiediPreventiviConDocumentoSingoloDocument = gql`
    query richiediPreventiviConDocumentoSingolo($input: InputRichiestaPreventiviConDocumentoSingolo) {
  richiediPreventiviConDocumentoSingolo(input: $input) {
    codiceRichiesta
    status {
      status
      errorMessage
    }
  }
}
    `;
export const RinunciaRichiestaDocument = gql`
    query rinunciaRichiesta($richiestaId: Int, $nota: String) {
  rinunciaRichiesta(richiestaId: $richiestaId, nota: $nota) {
    status {
      status
      errorMessage
    }
  }
}
    `;
export const RolesDocument = gql`
    query roles {
  roles {
    name
    description
  }
}
    `;
export const UserDocument = gql`
    query user($email: String!) {
  user(email: $email) {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;
export const UsersDocument = gql`
    query users {
  users {
    firstName
    lastName
    email
    avatar
    createdAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AddGroup(variables: AddGroupMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddGroupMutation> {
      return withWrapper(() => client.request<AddGroupMutation>(AddGroupDocument, variables, requestHeaders));
    },
    AddPolicy(variables: AddPolicyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddPolicyMutation> {
      return withWrapper(() => client.request<AddPolicyMutation>(AddPolicyDocument, variables, requestHeaders));
    },
    accettaPreventivo(variables?: AccettaPreventivoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AccettaPreventivoMutation> {
      return withWrapper(() => client.request<AccettaPreventivoMutation>(AccettaPreventivoDocument, variables, requestHeaders));
    },
    createDocumento(variables?: CreateDocumentoMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateDocumentoMutation> {
      return withWrapper(() => client.request<CreateDocumentoMutation>(CreateDocumentoDocument, variables, requestHeaders));
    },
    createNota(variables?: CreateNotaMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateNotaMutation> {
      return withWrapper(() => client.request<CreateNotaMutation>(CreateNotaDocument, variables, requestHeaders));
    },
    createReport(variables?: CreateReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReportMutation> {
      return withWrapper(() => client.request<CreateReportMutation>(CreateReportDocument, variables, requestHeaders));
    },
    createRole(variables: CreateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRoleMutation> {
      return withWrapper(() => client.request<CreateRoleMutation>(CreateRoleDocument, variables, requestHeaders));
    },
    createUser(variables?: CreateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUserMutation> {
      return withWrapper(() => client.request<CreateUserMutation>(CreateUserDocument, variables, requestHeaders));
    },
    deleteReport(variables: DeleteReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteReportMutation> {
      return withWrapper(() => client.request<DeleteReportMutation>(DeleteReportDocument, variables, requestHeaders));
    },
    downloadPrecontrattuale(variables: DownloadPrecontrattualeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadPrecontrattualeMutation> {
      return withWrapper(() => client.request<DownloadPrecontrattualeMutation>(DownloadPrecontrattualeDocument, variables, requestHeaders));
    },
    downloadReportTemplate(variables: DownloadReportTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadReportTemplateMutation> {
      return withWrapper(() => client.request<DownloadReportTemplateMutation>(DownloadReportTemplateDocument, variables, requestHeaders));
    },
    impersonates(variables: ImpersonatesMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ImpersonatesMutation> {
      return withWrapper(() => client.request<ImpersonatesMutation>(ImpersonatesDocument, variables, requestHeaders));
    },
    logout(variables?: LogoutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutMutation> {
      return withWrapper(() => client.request<LogoutMutation>(LogoutDocument, variables, requestHeaders));
    },
    pingMutation(variables?: PingMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingMutationMutation> {
      return withWrapper(() => client.request<PingMutationMutation>(PingMutationDocument, variables, requestHeaders));
    },
    recoverPassword(variables: RecoverPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RecoverPasswordMutation> {
      return withWrapper(() => client.request<RecoverPasswordMutation>(RecoverPasswordDocument, variables, requestHeaders));
    },
    resetPassword(variables: ResetPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, requestHeaders));
    },
    updateAvatar(variables: UpdateAvatarMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAvatarMutation> {
      return withWrapper(() => client.request<UpdateAvatarMutation>(UpdateAvatarDocument, variables, requestHeaders));
    },
    updateReport(variables: UpdateReportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateReportMutation> {
      return withWrapper(() => client.request<UpdateReportMutation>(UpdateReportDocument, variables, requestHeaders));
    },
    updateSelfPassword(variables: UpdateSelfPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateSelfPasswordMutation> {
      return withWrapper(() => client.request<UpdateSelfPasswordMutation>(UpdateSelfPasswordDocument, variables, requestHeaders));
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserMutation> {
      return withWrapper(() => client.request<UpdateUserMutation>(UpdateUserDocument, variables, requestHeaders));
    },
    updateUserPassword(variables: UpdateUserPasswordMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateUserPasswordMutation> {
      return withWrapper(() => client.request<UpdateUserPasswordMutation>(UpdateUserPasswordDocument, variables, requestHeaders));
    },
    basicLogin(variables: BasicLoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BasicLoginQuery> {
      return withWrapper(() => client.request<BasicLoginQuery>(BasicLoginDocument, variables, requestHeaders));
    },
    chiediUlteriorePreventivo(variables?: ChiediUlteriorePreventivoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ChiediUlteriorePreventivoQuery> {
      return withWrapper(() => client.request<ChiediUlteriorePreventivoQuery>(ChiediUlteriorePreventivoDocument, variables, requestHeaders));
    },
    datiSingolaPratica(variables?: DatiSingolaPraticaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DatiSingolaPraticaQuery> {
      return withWrapper(() => client.request<DatiSingolaPraticaQuery>(DatiSingolaPraticaDocument, variables, requestHeaders));
    },
    datiSingolaRichiesta(variables?: DatiSingolaRichiestaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DatiSingolaRichiestaQuery> {
      return withWrapper(() => client.request<DatiSingolaRichiestaQuery>(DatiSingolaRichiestaDocument, variables, requestHeaders));
    },
    downloadDocumento(variables?: DownloadDocumentoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DownloadDocumentoQuery> {
      return withWrapper(() => client.request<DownloadDocumentoQuery>(DownloadDocumentoDocument, variables, requestHeaders));
    },
    getGroups(variables?: GetGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetGroupsQuery> {
      return withWrapper(() => client.request<GetGroupsQuery>(GetGroupsDocument, variables, requestHeaders));
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewAccessTokenQuery> {
      return withWrapper(() => client.request<GetNewAccessTokenQuery>(GetNewAccessTokenDocument, variables, requestHeaders));
    },
    getPolicies(variables?: GetPoliciesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetPoliciesQuery> {
      return withWrapper(() => client.request<GetPoliciesQuery>(GetPoliciesDocument, variables, requestHeaders));
    },
    loggedUser(variables?: LoggedUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoggedUserQuery> {
      return withWrapper(() => client.request<LoggedUserQuery>(LoggedUserDocument, variables, requestHeaders));
    },
    ottieniConfigurazioni(variables?: OttieniConfigurazioniQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OttieniConfigurazioniQuery> {
      return withWrapper(() => client.request<OttieniConfigurazioniQuery>(OttieniConfigurazioniDocument, variables, requestHeaders));
    },
    ottieniDashboardCommissionale(variables?: OttieniDashboardCommissionaleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OttieniDashboardCommissionaleQuery> {
      return withWrapper(() => client.request<OttieniDashboardCommissionaleQuery>(OttieniDashboardCommissionaleDocument, variables, requestHeaders));
    },
    ottieniDashboardGenerale(variables?: OttieniDashboardGeneraleQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OttieniDashboardGeneraleQuery> {
      return withWrapper(() => client.request<OttieniDashboardGeneraleQuery>(OttieniDashboardGeneraleDocument, variables, requestHeaders));
    },
    ottieniPratiche(variables?: OttieniPraticheQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OttieniPraticheQuery> {
      return withWrapper(() => client.request<OttieniPraticheQuery>(OttieniPraticheDocument, variables, requestHeaders));
    },
    ottieniRichieste(variables?: OttieniRichiesteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OttieniRichiesteQuery> {
      return withWrapper(() => client.request<OttieniRichiesteQuery>(OttieniRichiesteDocument, variables, requestHeaders));
    },
    ping(variables?: PingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingQuery> {
      return withWrapper(() => client.request<PingQuery>(PingDocument, variables, requestHeaders));
    },
    report(variables: ReportQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReportQuery> {
      return withWrapper(() => client.request<ReportQuery>(ReportDocument, variables, requestHeaders));
    },
    reports(variables?: ReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReportsQuery> {
      return withWrapper(() => client.request<ReportsQuery>(ReportsDocument, variables, requestHeaders));
    },
    richediPreventivi(variables?: RichediPreventiviQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichediPreventiviQuery> {
      return withWrapper(() => client.request<RichediPreventiviQuery>(RichediPreventiviDocument, variables, requestHeaders));
    },
    richiediPreventiviConDocumentoSingolo(variables?: RichiediPreventiviConDocumentoSingoloQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RichiediPreventiviConDocumentoSingoloQuery> {
      return withWrapper(() => client.request<RichiediPreventiviConDocumentoSingoloQuery>(RichiediPreventiviConDocumentoSingoloDocument, variables, requestHeaders));
    },
    rinunciaRichiesta(variables?: RinunciaRichiestaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RinunciaRichiestaQuery> {
      return withWrapper(() => client.request<RinunciaRichiestaQuery>(RinunciaRichiestaDocument, variables, requestHeaders));
    },
    roles(variables?: RolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RolesQuery> {
      return withWrapper(() => client.request<RolesQuery>(RolesDocument, variables, requestHeaders));
    },
    user(variables: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(UserDocument, variables, requestHeaders));
    },
    users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UsersQuery> {
      return withWrapper(() => client.request<UsersQuery>(UsersDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;