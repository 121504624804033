// @ts-nocheck
import {useEffect, useState} from 'react';
import type {FC} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    Typography
} from '@material-ui/core';
import ChartSquareBarIcon from '../icons/ChartSquareBar';
import UserIcon from '../icons/User';
import Logo from './Logo';
import NavSection from './NavSection';
import Scrollbar from './Scrollbar';
import Users from "../icons/Users";
import {useSelector} from "react-redux";
import {Resources, Utente} from "../types/generated";
import {Can} from "../pages/login/authorizer";
import {useTranslation} from "react-i18next";
import FolderIcon from '@material-ui/icons/Folder';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import TuneIcon from '@material-ui/icons/Tune';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import NoteIcon from '@material-ui/icons/Note';

interface DashboardSidebarProps {
    onMobileClose: () => void;
    openMobile: boolean;
    openSidebar: boolean;
}


const Sidebar: FC<DashboardSidebarProps> = (props) => {

    const {t} = useTranslation();
    const {i18n} = useTranslation();

    const sections = [
        {
            title: 'General',
            items: [
                {
                    title: 'Dashboard',
                    path: '/',
                    icon: <ChartSquareBarIcon fontSize="small"/>,
                },
                {
                    title: 'Richieste',
                    path: '/richieste',
                    icon: <AllInboxIcon fontSize='small'/>
                },
                {
                    title: 'Commissionale',
                    path: '/commissionale',
                    icon: <LocalMallIcon fontSize="small" />
                },
                {
                    title: 'Note',
                    path: '/note',
                    icon: <NoteIcon fontSize='small' />
                },
                {
                    title: 'Account',
                    path: '/account',
                    icon: <UserIcon fontSize="small"/>
                },


            ]
        },
        {
            title: "Tabelle",
            items: [
                // YEOMAN ROUTE ANCHOR

                {
                    title: t("Reports"),
                    path: "/reports",
                    icon: <Users fontSize="small"/>,
                },

            ]
        },
        {
            title: t("users"),
            items: [
                {
                    title: t("users"),
                    path: '/users',
                    icon: <Users fontSize="small"/>,
                    children: [
                        {
                            title: t("list"),
                            path: '/users',
                            authorization: {resource: Resources.Users, action: 'allow'},
                        },
                        {
                            title: t("roles"),
                            path: '/roles',
                            authorization: {resource: Resources.Users, action: 'allow'},
                        },
                    ]
                },
            ]
        },
    ];


    const {onMobileClose, openMobile, openSidebar} = props;
    const location = useLocation();
    const user: Utente = useSelector(state => state.auth.user);

    const [authedSections, setAuthedSections] = useState([]);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);


    useEffect(() => {
        const setAuthedSec = async () => {
            let authed = [];

            const checkChildren = async children => {
                let authed = [];
                if (!children) return null;
                for (const item of children) {
                    if (item.authorization) {
                        let {resource, action} = item.authorization;
                        let can = await Can(action.toLowerCase(), resource.toLowerCase());
                        if (can) authed.push(item);
                    } else {
                        item.children = await checkChildren(item.children)
                        authed.push(item);
                    }
                }
                return authed;
            };

            const checkItems = async items => {
                let authed = [];
                for (const item of items) {
                    if (item.authorization) {
                        let {resource, action} = item.authorization;
                        let can = await Can(action.toLowerCase(), resource.toLowerCase());
                        if (can) authed.push(item);
                    } else {
                        if (!item.children) {
                            authed.push(item);
                            continue
                        }
                        item.children = await checkChildren(item.children)
                        if (item.children.length > 0) authed.push(item);
                    }
                }
                return authed;
            };

            for (const section of sections) {
                if (section.authorization) {
                    let {resource, action} = section.authorization;
                    let can = await Can(action.toLowerCase(), resource.toLowerCase());

                    if (can) authed.push(section);
                    return;
                }

                section.items = await checkItems(section.items)
                if (section.items.length > 0) {
                    authed.push(section);
                }
            }

            setAuthedSections(authed);
        };

        setAuthedSec();
    }, [user, i18n.language])

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Scrollbar options={{suppressScrollX: true}}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 2
                    }}
                >
                    <RouterLink to="/">
                    <Box
                            alt="Under development"
                            component="img"
                            src={`/static/IBL_logo.png`}
                            /* sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 400
                            }} */
                        />
                       {/*  <Logo
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        /> */}
                    </RouterLink>
                </Box>
                <Box sx={{p: 2}}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            backgroundColor: 'background.default',
                            borderRadius: 1,
                            display: 'flex',
                            overflow: 'hidden',
                            p: 2
                        }}
                    >
                        <Box sx={{ml: 2}}>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {user?.firstName} {user?.lastName}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {user?.email}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={{p: 2}}>
                    {authedSections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3
                                }
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Scrollbar>
            <Box
                sx={{
                    p: 2,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1
                }}
            >
                <Typography
                    color="textSecondary"
                    variant="caption"
                >
                    Versione: {process.env.REACT_APP_VERSION}
                </Typography>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'background.paper',
                            width: 280
                        }
                    }}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open={openSidebar}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'background.paper',
                            height: 'calc(100% - 64px) !important',
                            top: '64px !Important',
                            width: 280
                        }
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

Sidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default Sidebar;
