//@ts-ignore
import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  /* Table_MUI */
  TableHead,
  TableRow,
  TableCell,
  Divider,
  CardContent,
  TableBody,
  TextField,
  TableFooter,
  Skeleton,
  CardHeader,
  Chip,
  Tooltip,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Scrollbar from "src/material/Scrollbar";
import AddIcon from "@material-ui/icons/Add";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";

import GraphqlClient from "src/client/graphql.client";
import { useSnackbar } from "notistack";
//import dayjs from 'dayjs';
import { useParams } from "react-router-dom";
import {
  Table,
  SelectColumnFilter,
  DatePickerRangeFilter,
  DatePickerRangeFilterMethod,
} from "../../../components/Table/Table";
import {
  Allegati,
  Entity,
  FileConIntero,
  RispostaSingolaRichiesta,
} from "src/types/generated";
import moment from "moment";
import { useSelector as useReduxSelector } from "../../../store";
import JSZip, { files, folder } from "jszip";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Autocomplete } from "src/components/Autocomplete/Autocomplete";

// filepond plugin
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

interface DettaglioRichiestaProps {
  richiesta: RispostaSingolaRichiesta;
}

const AggiungiDocumento: FC<DettaglioRichiestaProps> = ({
  richiesta,
}: DettaglioRichiestaProps) => {
  const configuration = useReduxSelector(
    (state) => state.configuration.itemsMap
  );
  const configurationSelects = useReduxSelector(
    (state) => state.configuration.itemsForSelects
  );

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [caricamento, setCaricamento] = useState(false);

  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);

  /*  const [base64zip, setB64zip] = useState("");
     const [FilesInfo, setFilesInfo] = useState() */
  let base64zip = "";
  let FilesInfo: FileConIntero[] = [];

  const [files, setFiles] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState();

  const creaZip = async (files) => {
    var zip = new JSZip();
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].file.name, files[i].file);
    }

    console.log('FILES', files);

    if (!tipoDocumento) {
      enqueueSnackbar("Devi prima selezionare una tipologia per il documento!", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
      return;
    }

    // @ts-ignore
    let codice = tipoDocumento.codice;
    
    let listInfoFiles: FileConIntero[] = [
      {
        tipoDocumento: parseInt(codice),
        fileType: "pdf",
        nomeFile: files[0].file.name,
      }
    ];

   
    FilesInfo = listInfoFiles;
    //setFilesInfo(listInfoFiles)

    const content = await zip.generateAsync({ type: "base64" });
    //setB64zip(content);
    base64zip = content;

    //console.log("BASE64 GENERATO-> ", base64zip)

     //codice per debug in caso non funzioni il base64, crea un download per verifica locale
        //  var link = document.createElement('a');
        //  link.href = "data:application/zip;base64," + content;
        //  link.download = "nuovaRichiesta.zip";
        //  document.body.appendChild(link);
        //  link.click();
        //  document.body.removeChild(link); 
  };

  const caricaDocumenti = async () => {
    await creaZip(files);

    /* let filesInput: FileConIntero[] = []
        filesInput.push(FilesInfo) */

    let allegati: Allegati = {
      base64zip: base64zip,
      files: FilesInfo,
    };
    let Entity: Entity = {
      Tipo: "richiestaPreventivi",
      Id: "14",
    };

    // console.log("ALLEGATI DA INVIARE-> ", allegati)

    try {
      setCaricamento(true);
      await GraphqlClient.createDocumento({
        entity: Entity,
        allegati: allegati,
      });

      enqueueSnackbar("Documento caricato con successo", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "success",
      });

      // navigate('/richieste');
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Impossibile caricare documenti", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
    finally {
      setCaricamento(false)
    }
  };

  const scaricaDocumento = async (id) => {
    try {
      const res = await GraphqlClient.downloadDocumento({ documentoId: id });
      let base64 = res.downloadDocumento.documentoConIntero.base64;
      let tipoFile = res.downloadDocumento.documentoConIntero.fileType;
      let docID = res.downloadDocumento.documentoConIntero.documentoId;

      const bytes = atob(base64);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      let myBlob = new Blob([out], { type: "application/" + tipoFile });

      const URLblobObj = window.URL.createObjectURL(myBlob);

      let a = document.createElement("a");
      a.style.display = "none";
      a.href = URLblobObj;
      a.download = docID;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(URLblobObj);
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Impossibile scaricare il documento", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  useEffect(() => {
    // console.log("STATE DI BASE64", base64zip)
  });

  console.log("@@@@@@@@ CONFIGURATIOn", configurationSelects);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Documenti" />
            <Divider />
            <CardContent>
              <Box>
                <Scrollbar>
                  <Table
                    Data={richiesta.listDocumenti || []}
                    Columns={[
                      {
                        Header: "ID",
                        accessor: "documentoId",
                        width: 150,
                        disableFilters: true,
                      },
                      {
                        Header: "Descrizione",
                        accessor: "descrizione",
                        width: 150,
                        disableFilters: true,
                      },
                      {
                        Header: "Tipo",
                        accessor: "tipoDocumento",
                        width: 80,
                        disableFilters: true,
                        Cell: (row) => {
                          let map = configuration["tipoDocumento"];
                          if (!map) return "-- errore --";
                          return map[row.row.original.tipoDocumento];
                        },
                      },
                      {
                        Header: "Azioni",
                        width: 50,
                        Cell: (row) => {
                          return (
                            <>
                              <Tooltip title={"Scarica documento"}>
                                <IconButton
                                  aria-label="Download"
                                  color="primary"
                                  //onClick={() => scaricaDocumento(row.row.original.documentoId)}
                                  onClick={() =>
                                    window.open(row.row.original.url)
                                  }
                                >
                                  <CloudDownloadIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          );
                        },
                      },
                    ]}
                  />
                </Scrollbar>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Aggiungi Documento" />
            <Divider />
            <CardContent>
              <Box sx={{ mt: 2 }}>
                <Box sx={{ mt: 2 }}>
                  <Autocomplete
                    label="Tipologia documento"
                    options={configurationSelects.tipoDocumento}
                    getOptionLabel={(option: any) => {
                      return option.description;
                    }}
                    value={tipoDocumento}
                    onChange={(e, value) => {
                      setTipoDocumento(value);
                    }}
                  />
                </Box>
                <Box sx={{mt: 2}}>
                    <FilePond
                      files={files}
                      allowMultiple={false}
                      onupdatefiles={setFiles}
                      allowFileTypeValidation
                      fileValidateTypeLabelExpectedTypes="{allTypes}"
                      name="docReddituali"
                      acceptedFileTypes={["application/pdf"]}
                      labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                      //required={true}
                    />
                </Box>
               
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 3,
                }}
              >{
                caricamento ? <CircularProgress size={24}></CircularProgress> :
                <Button
                  color="primary"
                  disabled={files.length === 0}
                  type="submit"
                  variant="contained"
                  onClick={() => caricaDocumenti()}
                >
                  Aggiungi Documento
                </Button>}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AggiungiDocumento;
